import axios from "axios";
export function apiConfig() {
  return axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    timeout: 25000,
    // headers: {
    //     Accept: '*/*',
    //     'Content-Type': 'application/json',
    // }
  });
}

const apiAxios = apiConfig();

export { apiAxios };
