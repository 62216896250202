/**
 *
 */

const setClubObject = (club) => {
  localStorage.setItem("club", JSON.stringify(club));
};

const getClubObject = () => {
  const club = localStorage.getItem("club");
  return club ? JSON.parse(club) : null;
};

const setIsDefault = (isDefault) => {
  localStorage.setItem("isDefault", JSON.stringify(isDefault));
};

const getIsDefault = () => {
  const isDefault = localStorage.getItem("isDefault");
  return isDefault ? JSON.parse(isDefault) : true;
};

const setTokenTCAndExpireToken = (token, expTokenTC, sesion) => {
  localStorage.setItem("tokenTC", token);

  const exp = expTokenTC || Date.now() + 1800000; //+ 30min
  localStorage.setItem("expTokenTC", exp);

  localStorage.setItem("sesion", JSON.stringify(sesion));
};

const getTokenTC = () => {
  const tokenTC = localStorage.getItem("tokenTC");
  return tokenTC || null;
};

const getSesion = () => {
  const sesion = localStorage.getItem("sesion");
  return sesion ? JSON.parse(sesion) : null;
};

const checkExpTokenTC = () => {
  const expTokenTC = localStorage.getItem("expTokenTC");
  const now = Date.now();
  return !expTokenTC || parseInt(expTokenTC) < now ? false : true;
};

const setMemberObject = (member) => {
  localStorage.setItem("member", JSON.stringify(member));
};

const getMemberObject = () => {
  const member = localStorage.getItem("member");
  return member ? JSON.parse(member) : null;
};

const setSearchWeekObject = (searchWeek) => {
  localStorage.setItem("searchWeek", JSON.stringify(searchWeek));
};

const getSearchWeekObject = () => {
  const searchWeek = localStorage.getItem("searchWeek");
  return searchWeek ? JSON.parse(searchWeek) : null;
};

const setWeekObject = (week) => {
  localStorage.setItem("week", JSON.stringify(week));
};

const getWeekObject = () => {
  const week = localStorage.getItem("week");
  return week ? JSON.parse(week) : null;
};

const setSortWeeksObject = (sortWeeks) => {
  localStorage.setItem("sortWeeks", JSON.stringify(sortWeeks));
};

const getSortWeeksObject = () => {
  const sortWeeks = localStorage.getItem("sortWeeks");
  return sortWeeks ? JSON.parse(sortWeeks) : null;
};

module.exports = {
  setClubObject,
  getClubObject,
  setIsDefault,
  getIsDefault,
  setTokenTCAndExpireToken,
  getTokenTC,
  checkExpTokenTC,
  setMemberObject,
  getMemberObject,
  setSearchWeekObject,
  getSearchWeekObject,
  setWeekObject,
  getWeekObject,
  setSortWeeksObject,
  getSortWeeksObject,
  getSesion
};
