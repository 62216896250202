<template>
  <div
    id="mainForm:datascrollHorizontal_content"
    class="ui-dataview-content ui-widget-content"
  >
    <div class="ui-dataview-row ui-g" title="">
      <div
        class="ui-dataview-column ui-g-12 ui-md-12 u-1/2@xs u-1/1@md ux-options-service__item"
      >
        <div
          id="mainForm:datascrollHorizontal:0:hotelextended:otherOption"
          class="c-extended u-border--none minisheetMASTER-30800 c-extended--accommodation c-extended--accommodation--hotel bg-clr--white u-box-shadow--soft u-border-radius--big pb-0 u-display--none u-display--block@md"
          data-bathroom-count=""
          data-availposition="1"
          data-hotelname="Courtyard by Marriott Faro Blanco Resort"
          data-hotel-totalprice="US$6,778"
          data-hotel-showdifferenceinprices="false"
          data-destinationname="Florida Keys FL"
          data-latitude="24.710007"
          data-view-type="horizontal"
          data-longitude="-81.1034"
          data-user-currency="US$"
          data-hotel-popularity="8.971202635914334"
          data-hotel-category="3"
          data-is-apartment="false"
          data-bedroom-count=""
          data-needs-update="false"
          data-sizem2=""
          data-selected="no"
          data-code="MASTER-30800"
        >
          <div
            class="c-hover-effect__extended o-layout o-layout--stretch o-layout--gutter-default u-flex-wrap--nowrap clr--darkest-gray"
          >
            <div
              class="c-extended__img-wrapper o-layout__item u-1/2 u-cursor--pointer"
            >
              <div
                class="c-extended__bg-loading u-border-radius--left--big"
              ></div>
              <a
                id="mainForm:datascrollHorizontal:0:hotelextended:openHotel1"
                name="mainForm:datascrollHorizontal:0:hotelextended:openHotel1"
                target="_blank"
                class="dev-replace-target"
              >
                <div
                  class="c-fit-carousel c-fit-carousel--accommodation c-fit-carousel--first-position owl-carousel u-border-radius--left--big c-extended__carousel hotel-fit-carousel-mainForm:datascrollHorizontal:0:hotelextended:j_id_4py_c2 owl-carousel--no-transition owl-loaded"
                >
                  <div class="owl-stage-outer">
                    <div
                      class="owl-stage"
                      style="
                        transform: translate3d(0px, 0px, 0px);
                        transition: all 0s ease 0s;
                        width: 3300px;
                      "
                    >
                      <div
                        class="owl-item active"
                        style="width: 330px"
                        v-for="(img, idx) in week.imgs"
                        :key="idx"
                        v-if="imgActive === idx"
                      >
                        <div class="c-fit-carousel__item item u-1/1">
                          <div
                            class="c-hover-effect c-fit-carousel__hover-effect u-border-radius--left--big u-overflow--hidden c-hover-effect--accommodation c-hover-effect--change-color"
                          >
                            <div class="c-hover-effect__body tc-upc">
                              <div
                                class="c-hover-effect__message c-hover-effect__message--view o-group"
                              >
                                <i class="fa-regular fa-eye"></i
                                ><b> View accommodation </b>
                              </div>
                              <div
                                class="c-hover-effect__message c-hover-effect__message--select o-group"
                              >
                                <i class="fa-regular fa-check"></i
                                ><b> Select </b>
                              </div>
                            </div>
                            <div
                              class="c-hover-effect__overlaying bg-clr--overlaying"
                            ></div>
                          </div>
                          <span
                            id="mainForm:datascrollHorizontal:0:hotelextended:j_id_4py_c2:j_id_4py_c8:0:dev-classification-group-"
                            class="dev-classification-tag"
                          ></span>
                          <img
                            :src="img"
                            data-index="0"
                            data-error-src="https://booking.vacancyrewards.com/javax.faces.resource/images/no-photo-XS.jpg"
                            onerror="this.setAttribute('data-original-url', this.src); var errorUrl = this.getAttribute('data-error-src'); if(this.src !== errorUrl) {this.src = errorUrl};"
                            loading="lazy"
                            alt="Image"
                            width=""
                            height=""
                            onclick=""
                            style=""
                            class="lazy-img c-fit-carousel__img c-extended__img c-extended__img--carousel u-border-radius--left--big u-overflow--hidden img-fit-cover loaded"
                            data-ll-status="loaded"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="c-fit-carousel__nav o-flexbox o-flexbox--generic-row u-align-items--stretch"
                  >
                    <button
                      @click="previousImg"
                      type="button"
                      role="presentation"
                      class="c-fit-carousel__button c-fit-carousel__button--prev fas fa-angle-left"
                    ></button
                    ><button
                      @click="nextImg"
                      type="button"
                      role="presentation"
                      class="c-fit-carousel__button c-fit-carousel__button--next fas fa-angle-right"
                    ></button>
                  </div>
                  <div class="owl-dots disabled"></div>
                </div> </a
              ><a
                style="visibility: hidden"
                id="mainForm:datascrollHorizontal:0:hotelextended:j_id_4py_cv"
                class="ui-commandlink ui-widget c-extended__heart-checkbox c-heart-checkbox o-flexbox o-flexbox--generic-center"
                aria-label=""
              >
                <input
                  type="checkbox"
                  class="c-heart-checkbox__input pp-target-unchecked" />
                <span class="c-heart-checkbox__main"
                  ><i
                    class="fa-regular fa-heart c-heart-checkbox__heart c-heart-checkbox__heart--off"
                  ></i
                  ><i
                    class="fa-solid fa-heart c-heart-checkbox__heart c-heart-checkbox__heart--on"
                  ></i></span></a
              ><a
                style="visibility: hidden"
                id="mainForm:datascrollHorizontal:0:hotelextended:toggleHolidayPackagePreferredAccommodation"
                class="ui-commandlink ui-widget c-extended__heart-checkbox c-heart-checkbox o-flexbox o-flexbox--generic-center c-heart-checkbox--package dev-holiday-package-preferred-accommodation-select-ico"
                aria-label=""
              >
                <input
                  type="checkbox"
                  class="c-heart-checkbox__input pp-target-unchecked" />
                <span
                  class="c-heart-checkbox__main tc-tooltip"
                  title="The selected acomodations will be the only ones available for your holiday package"
                  ><i
                    class="fa-lg fa-solid fa-circle-plus clr--white"
                  ></i> </span
              ></a>
            </div>
            <div
              class="c-extended__main o-box o-layout__item o-flexbox--generic-column u-flex--11 u-cursor--pointer"
            >
              <div
                class="u-display--flex u-flex-direction--column u-flex-direction--row@lg u-justify-content--between@lg u-height--100 u-gap--tiny u-gap--default@lg"
              >
                <div
                  class="o-flexbox o-flexbox--generic-column u-justify-content--start u-gap--tiny u-min-width--auto"
                >
                  <div class="c-extended__heading">
                    <a
                      id="mainForm:datascrollHorizontal:0:hotelextended:openHotel2"
                      name="mainForm:datascrollHorizontal:0:hotelextended:openHotel2"
                      class="o-block o-block--tiny dev-replace-target"
                      @click="goResort"
                    >
                      <b
                        class="c-extended__title o-block__item u-display--block u-font-size--middle dev-hotel-title"
                      >
                        {{ week.ResortName }}

                        <span
                          class="c-hotel-status__category u-vertical-align--middle u-white-space--nowrap null"
                          style="visibility: hidden"
                          ><i class="fa-solid fa-star c-hotel-status__star"></i
                          ><i class="fa-solid fa-star c-hotel-status__star"></i
                          ><i class="fa-solid fa-star c-hotel-status__star"></i
                          ><i
                            class="fa-solid fa-star c-hotel-status__star hidden"
                          ></i
                          ><i
                            class="fa-solid fa-star c-hotel-status__star hidden"
                          ></i
                          ><i
                            class="fa-solid fa-star c-hotel-status__star hidden"
                          ></i
                        ></span>
                      </b>
                      <div class="u-display--none@lg">
                        <span
                          class="c-rating c-rating-- u-font-weight--700 c-rating--reverse@lg"
                        >
                          <span
                            class="c-rating__score u-border-radius u-line-height--1 bg-clr--rating100"
                          >
                            9 </span
                          ><span
                            class="o-flexbox o-flexbox--generic-column u-min-width--auto"
                            ><span
                              class="c-rating__grade u-line-height--1 u-white-space--nowrap clr--rating100"
                            >
                              Very good </span
                            ><span
                              class="u-font-size--small u-text-decoration--underline u-font-weight--400 clr--middle-gray u-hide-empty"
                            ></span></span
                        ></span>
                      </div>
                    </a>
                  </div>
                  <b
                    id="c-extended__distance--MASTER-30800"
                    class="c-extended__distance o-block__item u-font-size--small clr--darkest-gray interest-name u-white-space--initial"
                    ><a
                      id="mainForm:datascrollHorizontal:0:hotelextended:hotelMap"
                      name="mainForm:datascrollHorizontal:0:hotelextended:hotelMap"
                      class="c-inline-link"
                    >
                      {{ formatDate(week.CheckInDate) }} -
                      {{ formatDate(week.CheckOutDate) }}
                    </a>
                  </b>
                  <b
                    id="c-extended__distance--MASTER-30800"
                    class="c-extended__distance o-block__item u-font-size--small clr--darkest-gray interest-name u-white-space--initial"
                    ><a
                      id="mainForm:datascrollHorizontal:0:hotelextended:hotelMap"
                      name="mainForm:datascrollHorizontal:0:hotelextended:hotelMap"
                      class="c-inline-link"
                    >
                      {{ week.ResortAddress1 || ""
                      }}{{
                        week.ResortAddress2 ? `, ${week.ResortAddress2}` : ""
                      }}{{
                        week.ResortAddress3 ? `, ${week.ResortAddress3}` : ""
                      }}
                    </a>
                  </b>
                  <a
                    id="mainForm:datascrollHorizontal:0:hotelextended:openHotel4"
                    name="mainForm:datascrollHorizontal:0:hotelextended:openHotel4"
                    class="o-block__item o-block o-block--tiny dev-replace-target"
                    @click="goResort"
                  >
                    <div
                      class="c-extended__selected-combination o-block__item u-font-size--small"
                    >
                      <div
                        class="u-display--flex u-gap--tiny u-flex-wrap--wrap o-group o-group--small"
                      >
                        <span
                          ><i class="fa-regular fa-utensils fa-fw"></i>
                          {{ week.KitchenType }}
                        </span>
                        <span
                          ><i class="fa-regular fa-bed fa-fw"></i>
                          {{ week.UnitType }} |
                          {{ $func.parseIso("nonRefundable") }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="c-extended__description u-font-size--small@lg u-font-size--default@xl o-block__item clr--dark-gray"
                    >
                      {{ parseDescription() }}
                    </div>
                  </a>
                </div>
                <div class="o-group--reverse-margin u-text-align--right">
                  <div
                    class="o-flexbox o-flexbox--generic-column u-justify-content--between u-height--100"
                  >
                    <div
                      class="u-display--none u-display--block@lg"
                      style="visibility: hidden"
                    >
                      <span
                        class="c-rating c-rating-- u-font-weight--700 c-rating--reverse"
                      >
                        <span
                          class="c-rating__score u-border-radius u-line-height--1 bg-clr--rating100"
                        >
                          9 </span
                        ><span
                          class="o-flexbox o-flexbox--generic-column u-min-width--auto"
                          ><span
                            class="c-rating__grade u-line-height--1 u-white-space--nowrap clr--rating100"
                          >
                            Very good </span
                          ><span
                            class="u-font-size--small u-text-decoration--underline u-font-weight--400 clr--middle-gray u-hide-empty"
                          ></span></span
                      ></span>
                    </div>
                    <div>
                      <a
                        id="mainForm:datascrollHorizontal:0:hotelextended:openHotel3"
                        name="mainForm:datascrollHorizontal:0:hotelextended:openHotel3"
                        class="u-display--block dev-replace-target"
                        @click="goResort"
                      >
                        <div
                          class="c-extended__price c-price u-text-align--right u-white-space--nowrap"
                        >
                          <div
                            class="c-price__bottom u-font-size--small u-line-height--175"
                          >
                            {{ $func.parseIso("totalPrice") }}
                          </div>
                          <div
                            class="c-price__primary u-font-size--huge u-line-height--1 clr--price-difference clr--price-difference--neutral"
                          >
                            $ {{ week.Cost }} USD
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="c-extended__action u-display--flex u-justify-content--end u-gap--small"
              >
                <a
                  id="mainForm:datascrollHorizontal:0:hotelextended:openHotel5"
                  name="mainForm:datascrollHorizontal:0:hotelextended:openHotel5"
                  class="c-extended__button c-extended__button--details c-button bg-clr--brand u-font-size--small u-line-height--1 mt-5 ml-5 ml-0@lg view-hotel dev-open-hotel dev-replace-target"
                  @click="goResort"
                >
                  {{ $func.parseIso("moreDetails") }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          id="mainForm:datascrollHorizontal:0:hotelcard:hotelCard"
          class="c-card u-box-shadow--soft u-border-radius--big bg-clr--white u-border--none c-card--accommodation c-card--accommodation--hotel pp-target-hotel minisheetMASTER-30800 pi-col-link pi-right pp-bar c-card--vertical-flex o-layout--stretch mb-20 u-display--none@md c-hover-effect__wrapper"
          data-user-currency="US$"
          data-availposition="1"
          data-hotelname="Courtyard by Marriott Faro Blanco Resort"
          data-hotel-totalprice="US$6,778"
          data-hotel-showdifferenceinprices="false"
          data-hotel-popularity="8.971202635914334"
          data-hotel-category="3"
          data-destinationname="Florida Keys FL"
          data-latitude="24.710007"
          data-view-type="miniFicha"
          data-longitude="-81.1034"
          data-code="MASTER-30800"
        >
          <div class="c-card__main clr--darkest-gray">
            <div
              class="c-card__hero u-border-radius--none u-border-radius--top--big u-cursor--pointer"
            >
              <div class="c-card__top">
                <div class="c-card__price c-price">
                  <b
                    class="c-price__primary u-white-space--nowrap u-font-size--big price-tooltip clr--price-difference clr--price-difference--neutral"
                    data-toggle="tooltip"
                    data-placement="right"
                    title=""
                  >
                    $ {{ week.Cost }} USD
                  </b>
                  <div class="c-price__bottom u-font-size--small">
                    {{ $func.parseIso("totalPrice") }}
                  </div>
                </div>
              </div>
              <a
                id="mainForm:datascrollHorizontal:0:hotelcard:toggleFavourite"
                class="ui-commandlink ui-widget c-card__heart-checkbox c-heart-checkbox o-flexbox o-flexbox--generic-center"
                aria-label=""
                style="visibility: hidden"
              >
                <input
                  type="checkbox"
                  class="c-heart-checkbox__input pp-target-unchecked" />
                <span class="c-heart-checkbox__main"
                  ><i
                    class="fa-regular fa-heart c-heart-checkbox__heart c-heart-checkbox__heart--off u-text-shadow"
                  ></i
                  ><i
                    class="fa-solid fa-heart c-heart-checkbox__heart c-heart-checkbox__heart--on"
                  ></i></span></a
              ><a
                id="mainForm:datascrollHorizontal:0:hotelcard:toggleHolidayPackagePreferredAccommodation"
                class="ui-commandlink ui-widget c-extended__heart-checkbox c-heart-checkbox o-flexbox o-flexbox--generic-center c-heart-checkbox--package"
                aria-label=""
                style="visibility: hidden"
              >
                <input
                  type="checkbox"
                  class="c-heart-checkbox__input pp-target-unchecked" />
                <span
                  class="c-heart-checkbox__main tc-tooltip"
                  title="The selected acomodations will be the only ones available for your holiday package"
                  ><i
                    class="fa-lg fa-solid fa-circle-plus clr--white"
                  ></i> </span></a
              ><a
                id="mainForm:datascrollHorizontal:0:hotelcard:openHotelCard1"
                name="mainForm:datascrollHorizontal:0:hotelcard:openHotelCard1"
                class="dev-replace-target"
              >
                <div
                  class="c-fit-carousel c-fit-carousel--accommodation c-fit-carousel--first-position owl-carousel u-border-radius--left--big c-card__carousel u-border-radius--top--big hotel-fit-carousel-mainForm:datascrollHorizontal:0:hotelcard:j_id_4py_ij owl-carousel--no-transition owl-loaded"
                >
                  <div class="owl-stage-outer">
                    <div
                      class="owl-stage"
                      style="
                        transform: translate3d(0px, 0px, 0px);
                        transition: all 0s ease 0s;
                      "
                    >
                      <div
                        class="owl-item"
                        v-for="(img, idx) in week.imgs"
                        :key="idx"
                        v-if="imgActive === idx"
                      >
                        <div class="c-fit-carousel__item item u-1/1">
                          <div
                            class="c-hover-effect c-fit-carousel__hover-effect u-border-radius--left--big u-overflow--hidden c-hover-effect--accommodation c-hover-effect--change-color"
                          >
                            <div class="c-hover-effect__body tc-upc">
                              <div
                                class="c-hover-effect__message c-hover-effect__message--view o-group"
                              >
                                <i class="fa-regular fa-eye"></i
                                ><b> View accommodation </b>
                              </div>
                              <div
                                class="c-hover-effect__message c-hover-effect__message--select o-group"
                              >
                                <i class="fa-regular fa-check"></i
                                ><b> Select </b>
                              </div>
                            </div>
                            <div
                              class="c-hover-effect__overlaying bg-clr--overlaying"
                            ></div>
                          </div>
                          <span
                            id="mainForm:datascrollHorizontal:0:hotelcard:j_id_4py_ij:j_id_4py_ip:0:dev-classification-group-"
                            class="dev-classification-tag"
                          ></span>
                          <img
                            id="img-resort"
                            :src="img"
                            data-index="0"
                            data-error-src="https://booking.vacancyrewards.com/javax.faces.resource/images/no-photo-XS.jpg"
                            onerror="this.setAttribute('data-original-url', this.src); var errorUrl = this.getAttribute('data-error-src'); if(this.src !== errorUrl) {this.src = errorUrl};"
                            loading="lazy"
                            alt="Image"
                            width=""
                            height=""
                            onclick=""
                            style=""
                            class="lazy-img c-fit-carousel__img img-fit-cover loaded"
                            data-ll-status="loaded"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="c-fit-carousel__nav o-flexbox o-flexbox--generic-row u-align-items--stretch"
                  >
                    <button
                      @click="previousImg"
                      type="button"
                      role="presentation"
                      class="c-fit-carousel__button c-fit-carousel__button--prev fas fa-angle-left"
                    ></button
                    ><button
                      @click="nextImg"
                      type="button"
                      role="presentation"
                      class="c-fit-carousel__button c-fit-carousel__button--next fas fa-angle-right"
                    ></button>
                  </div>
                  <div class="owl-dots disabled"></div>
                </div>
              </a>
            </div>
            <div class="o-box--small pb-5 o-block o-block--tiny">
              <a
                id="mainForm:datascrollHorizontal:0:hotelcard:openHotelCard2"
                name="mainForm:datascrollHorizontal:0:hotelcard:openHotelCard2"
                class="c-card__body selected-combination u-cursor--pointer dev-replace-target"
              >
                <div
                  class="o-flexbox o-flexbox--generic-row u-align-items--start u-gap--small"
                >
                  <div
                    class="c-card__wrapper-title o-block__item"
                    style="margin-bottom: -20px"
                  >
                    <b
                      class="c-card__title hotel-name dev-hotel-title u-ellipsis-block u-ellipsis-block--two-lines u-line-height--125"
                    >
                      {{ week.ResortName }}
                    </b>
                    <span
                      class="c-hotel-status__category u-vertical-align--middle u-white-space--nowrap u-display--block"
                      style="visibility: hidden"
                      ><i class="fa-solid fa-star c-hotel-status__star"></i
                      ><i class="fa-solid fa-star c-hotel-status__star"></i
                      ><i class="fa-solid fa-star c-hotel-status__star"></i
                      ><i
                        class="fa-solid fa-star c-hotel-status__star hidden"
                      ></i
                      ><i
                        class="fa-solid fa-star c-hotel-status__star hidden"
                      ></i
                      ><i
                        class="fa-solid fa-star c-hotel-status__star hidden"
                      ></i
                    ></span>
                  </div>
                  <span
                    class="c-rating c-rating--sm u-font-weight--700 c-rating--reverse"
                    style="visibility: hidden"
                  >
                    <span
                      class="c-rating__score u-border-radius u-line-height--1 bg-clr--rating100"
                    >
                      9 </span
                    ><span
                      class="o-flexbox o-flexbox--generic-column u-min-width--auto"
                      ><span
                        class="c-rating__grade u-line-height--1 u-white-space--nowrap clr--rating100"
                      >
                        Very good </span
                      ><span
                        class="u-font-size--small u-text-decoration--underline u-font-weight--400 clr--middle-gray u-hide-empty"
                      ></span></span
                  ></span>
                </div>
                <b
                  id="c-extended__distance--MASTER-30800"
                  class="c-extended__distance o-block__item u-font-size--small clr--darkest-gray interest-name u-white-space--initial mt-5"
                ></b> </a
              ><b
                id="c-extended__distance--MASTER-30800"
                class="c-extended__distance o-block__item u-font-size--small clr--darkest-gray interest-name u-white-space--initial mt-5"
                ><a
                  id="mainForm:datascrollHorizontal:0:hotelcard:hotelMap"
                  name="mainForm:datascrollHorizontal:0:hotelcard:hotelMap"
                  class="c-inline-link dev-hotelMap"
                >
                  {{ formatDate(week.CheckInDate) }} -
                  {{ formatDate(week.CheckOutDate) }}
                </a></b
              >
              <b
                id="c-extended__distance--MASTER-30800"
                class="c-extended__distance o-block__item u-font-size--small clr--darkest-gray interest-name u-white-space--initial mt-5"
                ><a
                  id="mainForm:datascrollHorizontal:0:hotelcard:hotelMap"
                  name="mainForm:datascrollHorizontal:0:hotelcard:hotelMap"
                  class="c-inline-link dev-hotelMap"
                >
                  {{ week.ResortAddress1 || ""
                  }}{{ week.ResortAddress2 ? `, ${week.ResortAddress2}` : ""
                  }}{{ week.ResortAddress3 ? `, ${week.ResortAddress3}` : "" }}
                </a></b
              >
              <div class="o-block__item c-card__detail u-font-size--small">
                <i class="fa-regular fa-bed-alt"></i
                ><span>
                  {{ week.UnitType }} |
                  {{ $func.parseIso("nonRefundable") }}
                </span>
              </div>
              <div class="o-block__item c-card__detail u-font-size--small">
                <i class="fa-regular fa-utensils"></i
                ><span>{{ week.KitchenType }}</span>
              </div>
            </div>
          </div>
          <div
            class="c-button-group c-button-group--resize c-button-group--free-width c-card__action bg-clr--transparent o-box--small--horizontal u-boder-radius--big clr--darkest-gray pb-10"
          >
            <a
              id="mainForm:datascrollHorizontal:0:hotelcard:openHotelCard3"
              name="mainForm:datascrollHorizontal:0:hotelcard:openHotelCard3"
              class="c-card__button c-card__button--details c-button bg-clr--brand dev-open-hotel dev-replace-target"
              @click="goResort"
            >
              <span class="u-font-size--small">{{
                $func.parseIso("moreDetails")
              }}</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setWeekObject } from "../../utils/sesions";
export default {
  data() {
    return {
      iso: null,
      imgActive: 0,
    };
  },
  props: ["week", "token"],
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
      this.$forceUpdate();
    });
  },
  methods: {
    parseDescription() {
      if (this.week?.resort) {
        const { HighlightsEN, HighlightsSP } = this.week.resort || {};
        let description = this.iso === "en" ? HighlightsEN : HighlightsSP;
        if (!description) description = this.week.ResortHighLights;

        if (description.length > 250)
          return `${description.substring(0, 250)} ...`;
        else return description;
      } else {
        return this.$func.parseIso("loadingDescription") + "...";
      }
    },
    nextImg() {
      if (this.imgActive < this.week.imgs.length - 1) this.imgActive++;
    },
    previousImg() {
      if (this.imgActive > 0) this.imgActive--;
    },
    goResort() {
      this.$router.push(`/resort?weekId=${this.week._id}`);
      // this.$router.push(`/resort?token=${this.token}&weekId=${this.week._id}`);
    },
    formatDate(date) {
      return this.$moment(date).format("LL");
    },
  },
};
</script>

<style scoped>
#img-resort {
  @media (max-width: 767px) {
    width: 400px;
  }
}
</style>
