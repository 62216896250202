<template>
  <div>
    <Header />
    <modal name="pay" :width="800" :height="600" :adaptive="true">
      <ModalStripe
        :clientSecret="clientSecret"
        :price="price"
        :numberRef="numberRef"
      />
    </modal>
    <modal
      name="pay-checkout"
      :width="600"
      :height="900"
      :adaptive="true"
      :scrollable="true"
    >
      <ModalStripeCheckout
        :clientSecret="clientSecret"
        :numberRef="numberRef"
        :price="price"
      />
    </modal>
    <modal
      name="confirm"
      :width="400"
      :height="200"
      :adaptive="true"
      :clickToClose="false"
    >
      <h1>{{ $func.parseIso("successfulPayment") }}</h1>
      <h3>Se le enviara toda la información via email.</h3>
      <h4>Segundos: {{ count }}</h4>
    </modal>

    <div class="main only-hotel">
      <div
        id="carousel:modal:modalCargando"
        class="c-modal modalCargando c-modal-waiting setup-modal-back-button"
        data-size="small"
        role="dialog"
        data-closeable="false"
        tabindex="-1"
        aria-modal="true"
        data-active="false"
      ></div>
      <div id="carousel:destinations-photos" class="destinations-photos">
        <div
          class="fotos"
          style="display: none"
          data-city-name="Miami FL, United States of America"
          data-index="0"
          data-photo-0="https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FJzV9iszVbivw-7qvVLRqSHmWgDjd.jpeg"
          data-photo-1="https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FNGVTPelyHJ1w-ibBH4MC1XafyaOv.jpeg"
          data-photo-2="https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FEjm3zZbrL0mN-q80y6tLnvdUsg1a.jpeg"
          data-photo-3="https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2Fns6PdRaWRezt-aCezS6vw9rmKjBi.jpeg"
          data-photo-4="https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2Fp17DmpYVIY5M-Hyw3UDgwTMshbNH.jpeg"
          data-photo-5="https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FLsVsiozDaEm4-H81alUcYCQDmgZ6.jpeg"
          data-photo-6="https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FtZfvCLOYmEM7-wqIraDSaJmtrx8Y.jpeg"
        ></div>
      </div>
      <div id="error-panel" class="error-panel"></div>
      <div
        id="hotelMapModal"
        class="c-modal hotelMapModal c-modal--onmobile-fullscreen setup-modal-back-button"
        data-size="large"
        role="dialog"
        data-closeable="true"
        tabindex="-1"
        aria-modal="true"
        data-active="false"
      >
        <div class="c-modal__backmask"></div>
        <div class="c-modal__wrapper">
          <div class="c-modal__container">
            <div class="c-modal__close" style="">
              <button
                type="button"
                class="c-modal__close-btn"
                data-dismiss="modal"
              >
                <span
                  class="o-box--small--horizontal o-box--tiny--vertical u-font-weight--500 u-font-size--small u-border-radius--sm u-line-height--1 u-pointer-events--none"
                >
                  Close
                  <i
                    class="fa-solid fa-caret-right u-font-size--default"
                  ></i></span
                ><i class="u-display--block fa-regular fa-close"></i>
              </button>
            </div>
            <div class="c-modal__body">
              <div
                class="c-modal__header u-font-weight--600 u-line-height--1 u-hide-empty"
              ></div>
              <div class="hotelDataSheet">
                <div class="c-microservice c-microservice--hotel-map">
                  <div class="c-microservice__primary">
                    <div class="c-microservice__main p-0 u-border--none">
                      <div class="c-microservice__body">
                        <div class="c-microservice__content">
                          <span
                            id="openHotelMiniMap"
                            onclick="true &amp;&amp; !isInIframe() ? '' : travelc.pushViewStateToHistory()"
                          >
                            <div class="c-microservice__heading">
                              <b class="c-microservice__title mt-5"
                                >Clarion Inn &amp; Suites Miami International
                                Airport</b
                              >
                              <div
                                class="c-hotel-status null mt-5"
                                style="visibility: hidden"
                              >
                                <span class="c-hotel-status__category null"
                                  ><i
                                    class="fa-solid fa-star c-hotel-status__star"
                                  ></i
                                  ><i
                                    class="fa-solid fa-star c-hotel-status__star"
                                  ></i
                                  ><i
                                    class="fa-solid fa-star c-hotel-status__star hidden"
                                  ></i
                                  ><i
                                    class="fa-solid fa-star c-hotel-status__star hidden"
                                  ></i
                                  ><i
                                    class="fa-solid fa-star c-hotel-status__star hidden"
                                  ></i
                                  ><i
                                    class="fa-solid fa-star c-hotel-status__star hidden"
                                  ></i
                                ></span>
                              </div>
                            </div>
                            <div class="c-microservice__details">
                              <span class="c-microservice__detail">
                                <i class="fa-regular fa-link"></i
                                ><b> Chain: </b> &nbsp; Choice hotels </span
                              ><span class="c-microservice__detail">
                                <i class="fa-regular fa-map-marker-alt"></i
                                ><b>Address: </b>
                                &nbsp; 5301 nw 36th st, Miami springs 33166
                              </span>
                            </div></span
                          >
                        </div>
                      </div>
                      <div class="c-microservice__map-section">
                        <div
                          style="height: 500px"
                          class="c-microservice__map c-microservice__map--lg"
                          data-marker-target="_blank"
                          data-availposition="1"
                          data-hotelname="Clarion Inn &amp; Suites Miami International Airport"
                          data-market-feature="true &amp;&amp; !isInIframe() ? '' : travelc.pushViewStateToHistory()"
                          data-marker-link=""
                          data-latitude="25.807934"
                          data-is-apartment="false"
                          data-longitude="-80.28269"
                          data-code="72651"
                        >
                          <div id="hotel-map-container"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="confirmOutcomeActivities"
        class="ui-confirm-dialog ui-dialog ui-widget ui-widget-content ui-corner-all ui-shadow ui-hidden-container confirmOutcomeActivities"
        role="alertdialog"
        aria-describedby="confirmOutcomeActivities_content"
        aria-hidden="true"
        aria-modal="true"
        aria-labelledby="confirmOutcomeActivities_title"
        style="width: auto; height: auto"
      >
        <div
          class="ui-dialog-titlebar ui-widget-header ui-helper-clearfix ui-corner-top"
        >
          <span id="confirmOutcomeActivities_title" class="ui-dialog-title"
            >Activities</span
          >
        </div>
        <div
          class="ui-dialog-content ui-widget-content"
          id="confirmOutcomeActivities_content"
          style="height: auto"
        >
          <span class="ui-icon ui-icon-false ui-confirm-dialog-severity"></span
          ><span class="ui-confirm-dialog-message"
            >Would you like to see activities in Miami FL?</span
          >
        </div>
        <div
          class="ui-dialog-buttonpane ui-dialog-footer ui-widget-content ui-helper-clearfix"
        >
          <div class="o-flexbox o-flexbox--generic-row o-group--small pt-10">
            <a
              href="/ticket/ticketAvailability.xhtml?tripId=8"
              onclick="PF('block').show(); PF('confirmOutcomeActivities').hide();"
              class="c-button bg-clr--brand continue-tickets"
            >
              Yes </a
            ><a
              href="/summary.xhtml?tripId=8"
              onclick="PF('block').show(); PF('confirmOutcomeActivities').hide();"
              class="c-button bdr-clr--brand continue-summary"
            >
              No
            </a>
          </div>
        </div>
      </div>
      <span class="js-mobile-navigation--accommodation-detail"></span>
      <div
        class="c-sticky-header c-sticky-header--hidden o-position o-position--fixed u-1/1 u-zindex--128"
        style="top: -60px"
      >
        <div class="o-container o-container--large o-box--tiny--vertical">
          <div class="u-display--flex u-gap--tiny" style="height: 50px">
            <div class="c-navigation-availability__text">
              <div class="c-navigation-availability-title">
                <div
                  class="o-flexbox u-align-items--center u-gap--small u-flex-wrap--wrap"
                >
                  <small class="c-navigation-availability-title__small">
                    Miami FL</small
                  >
                  <span>-</span
                  ><span class="u-font-size--small"
                    >2 Apr 2024 - 30 Apr 2024</span
                  >
                </div>
                <b
                  class="c-navigation-availability-title__medium c-navigation-availability-title--ellipsis"
                >
                  Clarion Inn &amp; Suites Miami International Airport
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="accommodation-detail:c-mobile-navigation"
        class="c-mobile-navigation c-mobile-navigation--accommodation-detail"
      >
        <div
          class="c-mobile-navigation-bar c-mobile-navigation-bar--top u-display--block u-display--none@md"
          style="margin-top: 0px; visibility: hidden"
        >
          <div class="c-mobile-navigation-content-wrapper">
            <div class="c-navigation-availability">
              <div class="c-navigation-availability__text">
                <div class="c-navigation-availability-title">
                  <small class="c-navigation-availability-title__small">
                    Miami FL</small
                  ><b
                    class="c-navigation-availability-title__medium c-navigation-availability-title--ellipsis"
                  >
                    Clarion Inn &amp; Suites Miami International Airport
                  </b>
                </div>
              </div>
              <div
                class="c-navigation-availability__edit"
                data-toggle="modal"
                data-target="#composeModal"
              >
                <i class="fa-regular fa-search"></i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="c-mobile-navigation-bar c-mobile-navigation-bar--bottom u-display--block u-display--none@md"
        >
          <div class="c-mobile-navigation-content-wrapper">
            <div
              id="accommodation-detail:mobileFiltersPanel"
              class="c-navigation-availability__button-group dev-filterLoader"
            >
              <!-- <div class="c-navigation-availability__button">
                <a
                  class="c-button u-min-width--auto bdr-clr--brand u-1/1 ux-open-filters u-font-size--small u-font-size--default@sm"
                  onclick="openFiltersTab()"
                  ><span>Filters</span
                  ><i class="fa-regular fa-sliders-h ml-10"></i
                ></a>
              </div> -->
              <div class="c-navigation-availability__button">
                <div
                  class="c-button bg-clr--brand u-1/1 u-font-size--small u-font-size--default@sm"
                  @click="goBook()"
                >
                  {{ $func.parseIso("book") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="o-block__item o-box u-display--none@sm bg-clr--lightest-gray">
        <a href="/" data-toggle="modal" data-target="#composeModal"
          ><div
            class="o-box--small o-flexbox o-flexbox--generic-row u-align-items--center u-font-size--small bg-clr--white"
          >
            <div class="o-flexbox o-group">
              <div class="o-group">
                <i class="fa-regular fa-calendar-lines"></i
                ><span
                  >{{ week.ResortCountry }}, {{ week.ResortCity }},
                  {{ week.CheckInDate }} - {{ week.CheckOutDate }}</span
                >
              </div>
              <!-- <div class="o-group">
                <i class="fa-regular fa-user-friends"></i><span>2 Adults </span>
              </div> -->
            </div>
            <i class="fa-regular fa-search"></i></div
        ></a>
      </div>
      <div
        class="container o-container o-container--large o-block pb-0"
        id="hotel"
      >
        <div class="o-block__item o-layout o-layout--bottom">
          <div
            class="o-layout__item o-block u-1/1 u-2/3@md js-sticky-header-referece"
          >
            <div class="o-block__item u-display--none u-display--block@sm">
              <div class="o-flexbox u-align-items--center o-group--small"></div>
            </div>
            <div class="mb-10" @click="$router.go(-1)" style="cursor: pointer">
              <!-- <b
                class="clr--brand u-font-size--small u-font-size--default@sm u-display--block u-line-height--1"
                style="color: black;"
                ><a>{{ $func.parseIso("back") }}</a></b
              > -->
            </div>
            <div class="mb-10">
              <b
                class="clr--brand u-font-size--small u-font-size--default@sm u-display--block u-line-height--1"
                ><a>{{
                  `${getText("ResortCity")}, ${getText("ResortState")}`
                }}</a></b
              >
            </div>
            <div class="o-block__item">
              <div
                class="u-display--inline-flex@sm u-align-items--center u-flex-wrap--wrap o-group--small"
              >
                <div
                  id="sheethotelName"
                  class="u-display--inline-block o-group--small"
                >
                  <h1
                    class="u-font-size--big u-font-size--huge@sm u-font-weight--700 u-line-height--1 m-0 hotel-name"
                  >
                    {{ getText("ResortName") }}

                    <span
                      class="c-hotel-status__category u-vertical-align--middle u-white-space--nowrap c-hotel-status--sm"
                      style="visibility: hidden"
                      ><i class="fa-solid fa-star c-hotel-status__star"></i
                      ><i class="fa-solid fa-star c-hotel-status__star"></i
                      ><i
                        class="fa-solid fa-star c-hotel-status__star hidden"
                      ></i
                      ><i
                        class="fa-solid fa-star c-hotel-status__star hidden"
                      ></i
                      ><i
                        class="fa-solid fa-star c-hotel-status__star hidden"
                      ></i
                      ><i
                        class="fa-solid fa-star c-hotel-status__star hidden"
                      ></i
                    ></span>
                  </h1>
                </div>
              </div>
              <a class="u-display--block js-open-hotel-map"
                ><u class="u-display--block mt-5"
                  >{{
                    `${getText("ResortAddress1")} ${getText(
                      "ResortAddress2"
                    )} ${getText("ResortAddress3")}`
                  }}

                  <!-- <b>, 0.92 km from Center</b> -->
                </u></a
              >
              <div class="o-flexbox o-flexbox--generic-row u-align-items--end">
                <!-- <a
                  href="#"
                  class="u-display--inline-block"
                  onclick="$('html, body').animate({scrollTop: $('#section-popularity').offset().top - 140}, 1000);"
                  style="visibility: hidden;"
                  ><span class="c-rating c-rating--lg u-font-weight--700 pt-10">
                    <span
                      class="c-rating__score u-border-radius u-line-height--1 bg-clr--rating75"
                    >
                      6.5 </span
                    ><span
                      class="o-flexbox o-flexbox--generic-column u-min-width--auto"
                      ><span
                        class="c-rating__grade u-line-height--1 u-white-space--nowrap clr--rating75"
                      >
                        Nice </span
                      ><span
                        class="u-font-size--small u-text-decoration--underline u-font-weight--400 clr--middle-gray u-hide-empty"
                        >See scores</span
                      ></span
                    ></span
                  >
                </a> -->
                <!-- <div class="u-display--none@md" style="visibility: hidden;">
                  <div class="o-layout">
                    <a
                      id="toggleShareHotelMobile"
                      data-toggle="modal"
                      data-target=".modalHotelSocialNetworks"
                      class="o-layout__item o-flexbox--inline c-button c-button--square mr-10 bdr-clr--middle-gray clr--dark-gray"
                      ><i class="fa-regular fa-share-alt mr-5"></i></a
                    ><a
                      id="toggleFavouriteHotelMobile"
                      href="#"
                      class="ui-commandlink ui-widget o-layout__item o-flexbox--inline c-button c-button--square bdr-clr--middle-gray c-heart-checkbox c-heart-checkbox--gray clr--dark-gray"
                      aria-label=""
                      onclick="PF('blockfichas').show();;PrimeFaces.ab({s:&quot;toggleFavouriteHotelMobile&quot;,u:&quot;toggleFavouriteHotelMobile&quot;,onco:function(xhr,status,args,data){PF('blockfichas').hide();;}});return false;"
                    >
                      <input
                        type="checkbox"
                        class="c-heart-checkbox__input pp-target-unchecked"
                      />
                      <span
                        class="c-heart-checkbox__main u-font-size--middle m-0"
                        ><i
                          class="fa-regular fa-heart c-heart-checkbox__heart c-heart-checkbox__heart--off"
                        ></i
                        ><i
                          class="fa-solid fa-heart c-heart-checkbox__heart c-heart-checkbox__heart--on"
                        ></i></span
                      ><span class="u-display--none u-display--inline-block@md"
                        >Favourite</span
                      ></a
                    >
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- <div
            class="o-layout__item u-display--none u-display--inline-block@md u-1/1 u-1/3@md"
          >
            <div class="o-flexbox u-justify-content--end mt-20" style="visibility: hidden;">
              <a
                id="toggleFavouriteHotel"
                href="#"
                class="ui-commandlink ui-widget p-10 o-group--tiny c-heart-checkbox c-heart-checkbox--gray o-flexbox u-align-items--center clr--middle-gray"
                aria-label=""
                onclick="PF('blockfichas').show();;PrimeFaces.ab({s:&quot;toggleFavouriteHotel&quot;,u:&quot;toggleFavouriteHotel&quot;,onco:function(xhr,status,args,data){PF('blockfichas').hide();;}});return false;"
              >
                <input
                  type="checkbox"
                  class="c-heart-checkbox__input pp-target-unchecked"
                />
                <span class="c-heart-checkbox__main u-font-size--middle"
                  ><i
                    class="fa-regular fa-heart c-heart-checkbox__heart c-heart-checkbox__heart--off"
                  ></i
                  ><i
                    class="fa-regular fa-solid fa-heart c-heart-checkbox__heart c-heart-checkbox__heart--on"
                  ></i></span
                ><span class="">Favourite</span></a
              >
              <a
                href="#"
                class="c-heart-checkbox c-heart-checkbox--gray o-flexbox u-align-items--center clr--middle-gray ml-10"
                data-toggle="modal"
                data-target=".modalHotelSocialNetworks"
                ><i class="fa-regular fa-share-alt mr-5"></i
                ><span class=""> Share</span></a
              >
            </div>
          </div> -->
        </div>
        <div
          class="o-block__item u-display--none u-display--block@md o-box--small bg-clr--lightest-gray u-border-radius--big"
        >
          <div
            class="o-layout o-layout--gutter-small u-cursor--pointer"
            data-toggle="modal"
            data-target="#composeModal"
            id="openComposeModalButton"
          >
            <div class="o-layout__item u-1/1 u-2/12@md">
              <div class="c-icon-escort">
                <div
                  class="c-icon-escort__main c-icon-escort__main--left c-text-input u-border--none"
                >
                  <span class="u-ellipsis-inline">{{
                    `${getText("ResortCity")}${getText(
                      "ResortState",
                      ","
                    )}${getText("ResortCountry", ",")}`
                  }}</span>
                </div>
                <i
                  class="c-icon-escort__icon c-icon-escort__icon--left fa-light fa-location-dot"
                ></i>
              </div>
            </div>
            <div class="o-layout__item u-1/1 u-4/12@md">
              <div class="o-layout o-layout--gutter-small">
                <div class="o-layout__item u-1/1 u-8/12@md">
                  <div class="c-icon-escort u-ellipsis-inline">
                    <div
                      class="c-icon-escort__main c-icon-escort__main--left c-text-input u-border--none pl-30@md pl-40@lg"
                    >
                      <span class="u-ellipsis-inline">{{
                        `${formatDate(getText("CheckInDate"))} - ${formatDate(
                          getText("CheckOutDate")
                        )}`
                      }}</span>
                    </div>
                    <i
                      class="c-icon-escort__icon c-icon-escort__icon--left fa-light fa-calendar-lines"
                    ></i>
                  </div>
                </div>
                <div class="o-layout__item u-1/1 u-4/12@md">
                  <div class="c-icon-escort u-ellipsis-inline">
                    <div
                      class="c-icon-escort__main c-icon-escort__main--left c-text-input u-border--none pl-30@md pl-40@lg"
                    >
                      <span class="u-ellipsis-inline">{{
                        `${getText("Nights")} ${$func.parseIso("nights")}`
                      }}</span>
                    </div>
                    <i
                      class="c-icon-escort__icon c-icon-escort__icon--left fa-light fa-moon"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="o-layout__item u-1/1 u-2/12@md">
              <div class="c-icon-escort u-ellipsis-inline">
                <div
                  class="c-icon-escort__main c-icon-escort__main--left c-text-input u-border--none"
                >
                  <span class="u-ellipsis-inline">
                    {{ getText("UnitType") }}
                  </span>
                </div>
                <i
                  class="c-icon-escort__icon c-icon-escort__icon--left fa-light fa-bed-front"
                ></i>
              </div>
            </div>
            <div class="o-layout__item u-1/1 u-2/12@md">
              <div class="c-icon-escort u-ellipsis-inline">
                <div
                  class="c-icon-escort__main c-icon-escort__main--left c-text-input u-border--none"
                >
                  <span class="u-ellipsis-inline">
                    {{ $func.parseIso("maxOccupancy") }}:
                    {{ getText("MaxOccupancy") }}
                  </span>
                </div>
                <i
                  class="c-icon-escort__icon c-icon-escort__icon--left fa-light fa-bed-front"
                ></i>
              </div>
            </div>
            <div class="o-layout__item u-1/1 u-2/12@md">
              <div class="c-button bg-clr--brand u-1/1" @click="goBook">
                <span>{{ msgBtn ? msgBtn : $func.parseIso("book") }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="o-block__item u-position--relative">
          <div id="image-grid-component">
            <div
              class="c-image-grid c-image-grid--desktop o-flexbox o-flexbox--generic-row u-display--none u-display--flex@md"
            >
              <div
                class="u-1/1 u-2/4@md u-display--none u-display--block@md c-image-grid__column"
              >
                <div
                  id="dev-classification-tag-0"
                  class="dev-classification-tag"
                ></div>
                <a
                  class="fancybox fancybox_hotel_image"
                  data-fancybox="photo-gallery"
                  data-caption=""
                  data-index="0"
                  tabindex="0"
                >
                  <img
                    :src="getImg(0)"
                    data-index="0"
                    loading="lazy"
                    alt="Img"
                    style=""
                    class="lazy-img u-image-object--cover u-image-object--full u-border-radius--big loaded"
                    data-ll-status="loaded"
                    width=""
                    height=""
                  />
                </a>
              </div>
              <div
                class="u-1/1 u-1/4@md u-display--none u-display--block@md c-image-grid__column"
              >
                <div class="o-flexbox o-flexbox--generic-column u-height--full">
                  <div class="u-flex--11 u-position--relative mb-10">
                    <div
                      id="dev-classification-tag-1"
                      class="dev-classification-tag"
                    ></div>
                    <a
                      class="o-position o-position--top o-position--bottom u-1/1 fancybox fancybox_hotel_image"
                      data-fancybox="photo-gallery"
                      data-caption=""
                      data-index="1"
                      tabindex="1"
                    >
                      <img
                        :src="getImg(1)"
                        data-index="1"
                        loading="lazy"
                        alt="Img"
                        width=""
                        height=""
                        style=""
                        class="lazy-img u-image-object--cover u-image-object--full u-border-radius--big loaded"
                        data-ll-status="loaded"
                      />
                    </a>
                  </div>
                  <div class="u-flex--21 u-position--relative">
                    <div
                      id="dev-classification-tag-2"
                      class="dev-classification-tag"
                    ></div>
                    <a
                      class="o-position o-position--top o-position--bottom u-1/1 fancybox fancybox_hotel_image"
                      data-fancybox="photo-gallery"
                      data-caption=""
                      data-index="2"
                      tabindex="2"
                    >
                      <img
                        :src="getImg(2)"
                        data-index="2"
                        loading="lazy"
                        alt="Img3"
                        width=""
                        height=""
                        style=""
                        class="lazy-img u-image-object--cover u-image-object--full u-border-radius--big loaded"
                        data-ll-status="loaded"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div class="u-1/1 u-1/4@md c-image-grid__column">
                <div class="o-flexbox o-flexbox--generic-column u-height--full">
                  <div
                    class="u-flex--21 u-position--relative mb-10 u-display--none u-display--block@md"
                  >
                    <div class="mapa u-position--relative u-height--full">
                      <GoogleMap
                        v-if="week"
                        :lat="
                          week && week.GPS_Lat ? parseFloat(week.GPS_Lat) : 0
                        "
                        :lon="
                          week && week.GPS_Long ? parseFloat(week.GPS_Long) : 0
                        "
                        :resortName="week.ResortName"
                      />
                    </div>
                  </div>
                  <div class="u-flex--11 u-position--relative u-1/1">
                    <div
                      style="background-color: rgba(0, 0, 0, 0.5)"
                      class="u-display--none u-display--flex@md u-justify-content--center u-align-items--center o-position o-position--bottom o-position--right u-font-size--small u-border-radius--big u-zindex--4 o-group--small u-pointer-events--none u-1/1 u-height--full"
                      @click="openGallery"
                    >
                      <span
                        class="o-group--small clr--white u-font-size--default"
                        ><i class="fa-regular fa-images"></i
                        ><span>{{
                          `${getTotalImg()} ${$func.parseIso("morePictures")}`
                        }}</span></span
                      >
                    </div>
                    <div
                      class="o-position o-position--top o-position--bottom u-1/1 u-overflow--hidden"
                    >
                      <div
                        class="c-fit-carousel owl-carousel dev-image-grid u-height--full owl-loaded owl-carousel--no-transition"
                        id="grid-more-images"
                      >
                        <div class="owl-stage-outer">
                          <div
                            class="owl-stage"
                            style="
                              transform: translate3d(0px, 0px, 0px);
                              transition: all 0s ease 0s;
                              width: 8050px;
                            "
                          >
                            <div class="owl-item active" style="width: 350px">
                              <a
                                class="c-fit-carousel__item item fancybox fancybox_hotel_image"
                                data-fancybox="photo-gallery"
                                data-caption=""
                                data-index="4"
                                tabindex="4"
                                @click="openGallery"
                                style="cursor: pointer"
                              >
                                <img
                                  :src="getImg(3)"
                                  data-index="4"
                                  loading="lazy"
                                  alt="Img4"
                                  width=""
                                  height=""
                                  style=""
                                  class="lazy-img u-image-object--cover u-image-object--full u-border-radius--big loaded"
                                  data-ll-status="loaded"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="c-fit-carousel__nav o-flexbox o-flexbox--generic-row u-align-items--stretch disabled"
                        >
                          <button
                            type="button"
                            role="presentation"
                            class="c-fit-carousel__button c-fit-carousel__button--prev fa-regular fa-angle-left"
                          ></button
                          ><button
                            type="button"
                            role="presentation"
                            class="c-fit-carousel__button c-fit-carousel__button--next fa-regular fa-angle-right"
                          ></button>
                        </div>
                        <div class="owl-dots disabled"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div
                class="u-1/1 u-1/4@md c-image-grid__column"
                @click="openGallery"
              >
                <div class="o-flexbox o-flexbox--generic-column u-height--100">
                  <div class="u-flex--11 u-position--relative u-1/1">
                    <div
                      style="background-color: rgba(0, 0, 0, 0.5)"
                      class="u-display--none u-display--flex@md u-justify-content--center u-align-items--center o-position o-position--bottom o-position--right u-font-size--small u-border-radius--big u-zindex--4 o-group--small u-pointer-events--none u-1/1 u-height--100"
                    >
                      <span
                        class="o-group--small clr--white u-font-size--default"
                        ><i class="fa-regular fa-images"></i
                        ><span>{{
                          `${getTotalImg()} ${$func.parseIso("morePictures")}`
                        }}</span></span
                      >
                    </div>
                    <div
                      class="o-position o-position--top o-position--bottom u-1/1 u-overflow--hidden"
                    >
                      <div
                        class="c-fit-carousel owl-carousel dev-image-grid u-height--100 owl-loaded owl-carousel--no-transition"
                        id="grid-more-images"
                      >
                        <div class="owl-stage-outer">
                          <div
                            class="owl-stage"
                            style="
                              transform: translate3d(0px, 0px, 0px);
                              transition: all 0s ease 0s;
                              width: 19600px;
                            "
                          >
                            <div class="owl-item active" style="width: 350px">
                              <a
                                class="c-fit-carousel__item item fancybox fancybox_hotel_image"
                                data-fancybox="photo-gallery"
                                data-caption=""
                                data-index="4"
                                tabindex="4"
                                style="cursor: pointer"
                              >
                                <img
                                  :src="getImg(3)"
                                  data-index="4"
                                  loading="lazy"
                                  alt="Img4"
                                  class="lazy-img img-fit-cover u-border-radius--big loaded"
                                  data-ll-status="loaded"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="c-fit-carousel__nav o-flexbox o-flexbox--generic-row u-align-items--stretch disabled"
                        >
                          <button
                            type="button"
                            role="presentation"
                            class="c-fit-carousel__button c-fit-carousel__button--prev fa-regular fa-angle-left"
                          ></button
                          ><button
                            type="button"
                            role="presentation"
                            class="c-fit-carousel__button c-fit-carousel__button--next fa-regular fa-angle-right"
                          ></button>
                        </div>
                        <div class="owl-dots disabled"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div
              class="c-image-grid c-image-grid--mobile o-flexbox o-flexbox--generic-row u-display--flex u-display--none@md"
            >
              <div
                class="c-fit-carousel dev-image-grid owl-carousel u-height--100 owl-loaded owl-carousel--no-transition"
              >
                <div class="owl-stage-outer">
                  <div
                    class="owl-stage"
                    style="
                      transform: translate3d(0px, 0px, 0px);
                      transition: all 0s ease 0s;
                      width: 0px;
                    "
                  >
                    <div class="owl-item active" style="width: 0px">
                      <a
                        class="c-fit-carousel__item item fancybox fancybox_hotel_image"
                        data-fancybox="photo-gallery-mobile"
                        data-caption=""
                        data-index="0"
                        tabindex="0"
                        @click="openGallery"
                      >
                        <img
                          :src="getImg(0)"
                          data-index="0"
                          data-error-src="https://booking.vacancyrewards.com/javax.faces.resource/images/no-photo-XS.jpg"
                          loading="lazy"
                          alt="img"
                          :style="`width: ${widthWindow - 40}px; ${
                            (timeBool && 'opacity: 0.5;') || ''
                          }`"
                          class="lazy-img img-fit-cover u-border-radius--big"
                          data-ll-status="native"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="c-fit-carousel__nav o-flexbox o-flexbox--generic-row u-align-items--stretch disabled"
                >
                  <button
                    type="button"
                    role="presentation"
                    class="c-fit-carousel__button c-fit-carousel__button--prev fa-regular fa-angle-left"
                  ></button
                  ><button
                    type="button"
                    role="presentation"
                    class="c-fit-carousel__button c-fit-carousel__button--next fa-regular fa-angle-right"
                  ></button>
                </div>
                <div class="owl-dots disabled"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-clr--white o-position--sticky o-position--top c-tab-buttons u-zindex--8 u-display--none u-display--block@md mt-10 u-transition"
        style="top: 0px"
      >
        <div class="o-container o-container--large">
          <div
            id="accommodationSectionPanel"
            class="o-layout o-layout--stretch u-flex-wrap--nowrap u-text-align--center u-text-transform--uppercase dev-accommodationSectionPanel u-border--bottom bdr-clr--lighter-gray"
          >
            <div class="o-layout__item u-1/1">
              <a
                class="c-tab-button c-tab-button--large u-1/1 dev-go-to-section-accommodation"
                data-scroll-tab="hotel"
              >
                {{ $func.parseIso("aboutProperty") }}
              </a>
            </div>
            <div class="o-layout__item u-1/1">
              <a
                class="c-tab-button c-tab-button--large u-1/1 dev-go-to-section-facilities"
                data-scroll-tab="facilities"
              >
                {{ $func.parseIso("services") }}
              </a>
            </div>
            <!-- <div class="o-layout__item u-1/1">
              <a
                class="c-tab-button c-tab-button--large u-1/1 dev-go-to-section-rooms"
                data-scroll-tab="distribution"
              >
                {{ $func.parseIso("policies") }}
              </a>
            </div>
            <div class="o-layout__item u-1/1">
              <a
                class="c-tab-button c-tab-button--large u-1/1 dev-go-to-section-openMap"
              >
                {{ $func.parseIso("importantInformation") }}
              </a>
            </div> -->
            <!-- <div class="o-layout__item u-1/1 dev-reviews-button-section">
              <a
                href="#"
                class="c-tab-button c-tab-button--large u-1/1 dev-go-to-section-reviews"
                data-scroll-tab="reviews-comments"
                onclick="goToSection('#section-reviews-comments')"
              >
                Reviews
              </a>
            </div>
            <div class="o-layout__item u-1/1">
              <a
                href="#"
                class="c-tab-button c-tab-button--large u-1/1 dev-go-to-section-destination"
                data-scroll-tab="destination"
                onclick="goToSection('#destinationInformationPanel')"
              >
                Surroundings
              </a>
            </div> -->
          </div>
        </div>
      </div>
      <div class="container o-container o-container--large mt-20">
        <div class="o-block o-block--big">
          <div
            id="section-accommodation"
            data-scroll-section="hotel"
            class="o-block__item scroll-section"
          >
            <div
              class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@md u-align-items--start u-flex-direction--column-reverse u-flex-direction--row@md u-gap--big u-gap--default@md u-gap--bigger@lg"
            >
              <div class="u-1/1">
                <h2 class="c-title--main u-font-weight--700 m-0">
                  {{ $func.parseIso("aboutProperty") }}
                </h2>
                <div
                  id="descriptionHotelPanel"
                  class="ui-outputpanel ui-widget"
                >
                  <div class="o-box--middle--vertical descripcion dev-de">
                    <div
                      id="read-more--j_id_fm"
                      class="c-read-more c-read-more--collapsed c-read-more--collapse-active"
                    >
                      <div
                        class="js-readmore-container u-overflow--hidden m-0 c-read-more__line-height--default"
                        style="
                          line-height: 1.5;
                          font-size: 14px;
                          max-height: 105px;
                        "
                      >
                        <div class="js-readmore-element">
                          {{ getDescription() }}
                        </div>
                      </div>
                      <div
                        class="c-read-more__link c-inline-link pt-5 clr--dark-gray"
                      >
                        <!-- <div
                          role="button"
                          tabindex="0"
                          class="c-read-more__link--more u-text-decoration--underline u-cursor--pointer"
                        >
                          <b>Read more</b>
                        </div>
                        <div
                          role="button"
                          tabindex="0"
                          class="c-read-more__link--less u-text-decoration--underline u-cursor--pointer"
                        >
                          <b>Read less</b>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="section-facilities"
            data-scroll-section="facilities"
            class="o-block__item o-block scroll-section dev-section-facilities"
          >
            <div class="o-block__item">
              <h2 class="c-title--main u-font-weight--bold m-0 pb-5">
                {{ $func.parseIso("services") }}
              </h2>
            </div>
            <div
              class="o-layout o-layout--stretch o-layout--gutter-small o-layout--gutter-default@md o-layout--fix u-justify-content--start mb-10 mb-0@md"
            >
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
                v-for="(item, idx) in getAmenities()"
              >
                <!-- <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                  v-for="(item, idx) in getAmenities()"
                  :key="idx"
                >
                  <i
                    class="fa-regular fa-washing-machine u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">{{
                    item[iso] || "n/a"
                  }}</span>
                </div> -->
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <img
                    :src="`https://vacancyelite.com/images/Amenidades/${item.img}`"
                    alt=""
                    width="30"
                  />
                  <span class="u-font-size--small">{{
                    iso === "es" ? item.es : item.en
                  }}</span>
                </div>
              </div>
              <!-- <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-language u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Multilingual staff</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-dumbbell u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Fitness room</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="ico-tc-WIFI u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Wi-fi</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa fa-taxi u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Airport Shuttle</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-dog-leashed u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Service Animal allowed</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-vault u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Hotel safe</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-grill u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">BBQ Facilities</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa fa-wheelchair u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Wheelchair-accessible</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-bag-shopping u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Shop</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-elevator u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Elevator</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-dollar-sign u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Business centre</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-handshake u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Meeting room</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-mug-hot u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small"
                    >Tea and coffee making facilities</span
                  >
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-newspaper u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Newspapers</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-user-hair-buns u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Hairdressing salon</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-fork-knife u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Restaurant</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-gamepad u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Game room</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-square-parking u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Accessible parking</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-suitcase-rolling u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Luggage room</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-flower-daffodil u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Garden</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-bell-concierge u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">24-hour reception</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-microwave u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Microwave</span>
                </div>
              </div>
              <div
                class="o-layout__item u-1/2 u-1/3@xs u-1/4@sm u-1/5@md u-1/6@xl mb-10 mb-20@md"
              >
                <div
                  class="o-flexbox u-gap--tiny u-gap--small@sm u-align-items--center o-box--default--vertical o-box--middle--horizontal u-border--full bdr-clr--lighter-gray u-border-radius u-line-height--11"
                >
                  <i
                    class="fa-regular fa-ban u-font-size--bigger u-font-size--huge@xs"
                  ></i>
                  <span class="u-font-size--small">Pets not allowed</span>
                </div>
              </div> -->
            </div>
            <!-- <div class="u-line-height--1">
              <span
                data-toggle="collapse"
                data-target="#otherservices"
                class="c-inline-link c-inline-link--underline u-cursor--pointer u-text-transform--uppercase u-font-weight--600 u-font-size--small u-collapse-turn-arrow collapsed"
              >
                Other services
                <i
                  class="fa-regular fa-chevron-down u-collapse-turn-arrow__icon"
                ></i
              ></span>
            </div> -->
          </div>
          <div id="destinationInformationPanel" class="o-block o-block--big">
            <!-- <div
              class="u-position--relative scroll-section dev-destination-information"
              data-scroll-section="destination"
              style="height: 350px"
            >
              <picture
                ><source
                  media="(min-width: 1200px)"
                  data-srcset="https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FcS2HaBmGX1vB-SA7I13LF5WkylTt.jpeg"
                  srcset="
                    https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FcS2HaBmGX1vB-SA7I13LF5WkylTt.jpeg
                  " />
                <source
                  media="(min-width: 768px)"
                  data-srcset="https://cdn5.travelconline.com/unsafe/fit-in/1200x0/filters:quality(75):format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FcS2HaBmGX1vB-SA7I13LF5WkylTt.jpeg"
                  srcset="
                    https://cdn5.travelconline.com/unsafe/fit-in/1200x0/filters:quality(75):format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FcS2HaBmGX1vB-SA7I13LF5WkylTt.jpeg
                  " />
                <img
                  data-src="https://cdn5.travelconline.com/unsafe/fit-in/768x0/filters:quality(75):format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FcS2HaBmGX1vB-SA7I13LF5WkylTt.jpeg"
                  src="https://cdn5.travelconline.com/unsafe/fit-in/768x0/filters:quality(75):format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FcS2HaBmGX1vB-SA7I13LF5WkylTt.jpeg"
                  data-error-src="/javax.faces.resource/images/no-photo-XS.jpg"
                  alt=""
                  style=""
                  class="img-fit-cover u-border-radius--big lazy-img loaded"
                  loading="lazy"
                  data-ll-status="loaded"
              /></picture>
            </div> -->
            <!-- <div class="o-block__item">
              <div
                class="o-box pt-40@md pb-30@md pr-40@md pl-40@md bg-clr--lightest-gray u-border-radius--big"
              >
                <div
                  class="o-layout@md o-layout--gutter-big@md o-layout--gutter-huge@lg pt-20"
                >
                  <div class="o-layout__item u-1/1 u-3/12@lg pb-20">
                    <h2
                      class="u-font-size--bigger u-font-weight--700 m-0 pb-10 pb-20@lg"
                    >
                      {{ $func.parseIso("policies") }}
                    </h2>
                  </div>
                  <div class="o-layout__item u-1/1 u-9/12@lg">
                    <div class="o-layout@sm o-layout--gutter-default@sm">
                      <div class="o-layout__item u-1/1 u-1/2@sm pb-30">
                        <h3 class="u-font-size--middle u-font-weight--700 mt-0">
                          Check-in
                        </h3>
                        <ul class="m-0">
                          <li class="pb-5">
                            <span>Checkin from 3:00 PM to anytime</span>
                          </li>
                          <li class="pb-5">
                            <span>Minimum age for check-in: 21 years old</span>
                          </li>
                        </ul>
                      </div>
                      <div class="o-layout__item u-1/1 u-1/2@sm pb-30">
                        <h3 class="u-font-size--middle u-font-weight--700 mt-0">
                          Check-out
                        </h3>
                        <span class="u-display--block"
                          >Checkout before 11:00 AM
                        </span>
                      </div>
                      <div class="o-layout__item u-1/1 pb-30">
                        <h3 class="u-font-size--middle u-font-weight--700 mt-0">
                          Special check-in instructions
                        </h3>
                        <span class="u-display--block"
                          >This property offers transfers from the cruise
                          terminal (surcharges may apply). Guests must contact
                          the property with arrival details before travel, using
                          the contact information on the booking confirmation.
                          Front desk staff will greet guests on arrival.</span
                        >
                      </div>
                      <div class="o-layout__item u-1/1 pb-30 u-allow-list">
                        <h3 class="u-font-size--middle u-font-weight--700 mt-0">
                          Pets
                        </h3>
                        <span class="u-display--block pb-5">
                          Service animals are allowed
                        </span>
                        <span class="u-display--block pb-5">
                          Service animals are exempt from fees/restrictions
                        </span>
                        <span class="u-display--block pb-5">
                          Pets not allowed
                        </span>
                        <span class="u-display--block"></span>
                      </div>
                      <div class="o-layout__item u-1/1 pb-30">
                        <h3 class="u-font-size--middle u-font-weight--700 mt-0">
                          Children and extra beds
                        </h3>
                        <span class="u-display--block"></span
                        ><span class="u-display--block"
                          >No cribs (infant beds) available</span
                        ><span class="u-display--block"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="o-block__item">
              <div
                class="o-box pt-40@md pb-40@md pr-40@md pl-40@md bg-clr--lightest-gray u-border-radius--big"
              >
                <div
                  class="o-layout@md o-layout--gutter-big@md o-layout--gutter-huge@lg pt-20 pb-20"
                >
                  <div class="o-layout__item u-1/1 u-3/12@lg pb-20">
                    <h2
                      class="u-font-size--bigger u-font-weight--700 m-0 pb-10 pb-20@lg"
                    >
                      {{ $func.parseIso("importantInformation") }}
                    </h2>
                  </div>
                  <div class="o-layout__item u-1/1 u-9/12@lg">
                    <div
                      class="o-block o-block--big u-allow-list u-allow-list--space-between"
                    >
                      <div class="o-block__item">
                        <h3
                          class="u-font-size--middle u-font-weight--700 m-0 pb-20"
                        >
                          Fees
                        </h3>
                        <p>
                          You'll be asked to pay the following charges at the
                          property:
                        </p>
                        <ul>
                          <li>Deposit: USD 100 per accommodation, per stay</li>
                          <li>Breakage deposit: USD 100 per stay</li>
                        </ul>
                        <p>
                          We have included all charges provided to us by the
                          property.
                        </p>
                      </div>
                      <div class="o-block__item">
                        <h3
                          class="u-font-size--middle u-font-weight--700 m-0 pb-20"
                        >
                          Optional fees
                        </h3>
                        <ul>
                          <li>
                            Fee for cooked-to-order breakfast: approximately USD
                            6–12 for adults, and USD 6–12 for children
                          </li>
                          <li>
                            Early check-in fee: USD 25.00 (subject to
                            availability)
                          </li>
                          <li>
                            Late check-out fee: USD 25.00 (subject to
                            availability)
                          </li>
                        </ul>
                        <p>
                          The above list may not be comprehensive. Fees and
                          deposits may not include tax and are subject to
                          change.
                        </p>
                      </div>
                      <div class="o-block__item">
                        <h3
                          class="u-font-size--middle u-font-weight--700 m-0 pb-20"
                        >
                          Other details
                        </h3>
                        <ul>
                          <li>
                            Extra-person charges may apply and vary depending on
                            property policy
                          </li>
                          <li>
                            Government-issued photo identification and a credit
                            card may be required at check-in for incidental
                            charges
                          </li>
                          <li>
                            Special requests are subject to availability upon
                            check-in and may incur additional charges; special
                            requests cannot be guaranteed
                          </li>
                          <li>
                            The name on the credit card used at check-in to pay
                            for incidentals must be the primary name on the
                            guestroom reservation
                          </li>
                          <li>
                            This property accepts credit cards, debit cards, and
                            cash
                          </li>
                          <li>
                            Safety features at this property include a carbon
                            monoxide detector, a fire extinguisher, a smoke
                            detector, a security system, a first aid kit, and
                            window guards
                          </li>
                          <li>
                            This property affirms that it follows the cleaning
                            and disinfection practices of Commitment to Clean
                            (Choice)
                          </li>
                          <li>
                            Please note that cultural norms and guest policies
                            may differ by country and by property; the policies
                            listed are provided by the property
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Up to 3 children 18 years old and younger stay free
                            when occupying the parent or guardian's room, using
                            existing bedding.
                          </li>
                          <li>
                            The property has connecting/adjoining rooms, which
                            are subject to availability and can be requested by
                            contacting the property using the number on the
                            booking confirmation.
                          </li>
                          <li>Contactless check-out is available.</li>
                          <li>
                            This property welcomes guests of all sexual
                            orientations and gender identities (LGBTQ+
                            friendly).
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div
          id="j_id_1fp:modalHotelSocialNetworks"
          class="c-modal modalHotelSocialNetworks"
          data-size="small"
          role="dialog"
          data-closeable="true"
          tabindex="-1"
          aria-modal="true"
          data-active="false"
        >
          <div
            class="c-modal__backmask"
            onclick="$('.modalHotelSocialNetworks').modal('hide')"
          ></div>
          <div class="c-modal__wrapper">
            <div class="c-modal__container">
              <div class="c-modal__close" style="">
                <button
                  type="button"
                  class="c-modal__close-btn"
                  data-dismiss="modal"
                >
                  <span
                    class="o-box--small--horizontal o-box--tiny--vertical u-font-weight--500 u-font-size--small u-border-radius--sm u-line-height--1 u-pointer-events--none"
                  >
                    Close
                    <i
                      class="fa-solid fa-caret-right u-font-size--default"
                    ></i></span
                  ><i class="u-display--block fa-regular fa-close"></i>
                </button>
              </div>
              <div class="c-modal__body">
                <div
                  class="c-modal__header u-font-weight--600 u-line-height--1 u-hide-empty"
                ></div>
                <div class="o-block hidden-print u-text-align--center">
                  <div class="o-block__item">
                    <i
                      class="fa-regular fa-share-alt clr--success u-font-size--jumbo"
                    ></i>
                    <div class="mt-10 clr--light-gray">Share</div>
                    <h2 class="u-font-size--big mt-5 mb-10"><b></b></h2>
                  </div>
                  <div class="o-block__item">
                    <div class="o-layout o-layout--center">
                      <div class="o-layout__item u-4/5@xs">
                        <ul>
                          <li role="presentation">
                            <a
                              href="#share-twitter"
                              class="c-button u-1/1 u-justify-content--between mb-10 fakeLink share-social-btn"
                              tabindex="-1"
                              role="menuitem"
                              title="Twitter"
                              data-share-url="https://twitter.com/intent/tweet?url=https%3A%2F%2Fbooking.vacancyrewards.com%2Fen%2Faccommodation%2F72651%2Fclarion-inn-suites-miami-international-airport&amp;text=Clarion+Inn+%26+Suites+Miami+International+Airport&amp;via=**TWITTER_ACCOUNT_NAME_MISSING**&amp;related=**TWITTER_ACCOUNT_NAME_MISSING**"
                              ><span>Twitter</span
                              ><i class="fab fa-x-twitter clr--twitter"></i
                            ></a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#share-facebook"
                              class="c-button u-1/1 u-justify-content--between mb-10 fakeLink share-social-btn"
                              tabindex="-1"
                              role="menuitem"
                              title="Facebook"
                              data-share-url="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbooking.vacancyrewards.com%2Fen%2Faccommodation%2F72651%2Fclarion-inn-suites-miami-international-airport"
                              ><span>Facebook</span
                              ><i class="fab fa-facebook clr--facebook"></i
                            ></a>
                          </li>
                          <li role="presentation">
                            <a
                              href="https://web.whatsapp.com/send?text=https%3A%2F%2Fbooking.vacancyrewards.com%2Fen%2Faccommodation%2F72651%2Fclarion-inn-suites-miami-international-airport"
                              tabindex="-1"
                              target="_blank"
                              class="c-button u-1/1 u-justify-content--between mb-10 share-social-btn"
                              title="Whatsapp"
                              role="menuitem"
                              data-action="share/whatsapp/share"
                            >
                              <span>Whatsapp</span
                              ><i class="fab fa-whatsapp clr--whatsapp"></i
                            ></a>
                          </li>
                          <li role="presentation">
                            <a
                              href="https://telegram.me/share/url?url=https%3A%2F%2Fbooking.vacancyrewards.com%2Fen%2Faccommodation%2F72651%2Fclarion-inn-suites-miami-international-airport&amp;text=Clarion+Inn+%26+Suites+Miami+International+Airport"
                              tabindex="-1"
                              target="_blank"
                              class="c-button u-1/1 u-justify-content--between mb-10 share-social-btn"
                              title="Telegram"
                              role="menuitem"
                            >
                              <span>Telegram</span
                              ><i class="fab fa-telegram clr--telegram"></i
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer :isDefault="club && false" :club="club" /> -->
    <Fancybox
      :options="{
        Carousel: {
          infinite: false,
        },
      }"
    >
      <a
        data-fancybox="gallery"
        :href="item"
        v-for="(item, idx) in getImgs()"
        :ref="`item${idx + 1}`"
        style="display: none"
      >
        <img :src="item" width="200" height="150" />
      </a>
    </Fancybox>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import ModalStripe from "./ModalStripe.vue";
import ModalStripeCheckout from "./ModalStripeCheckout.vue";
import Fancybox from "../components/Fancybox.vue";
import GoogleMap from "../components/GoogleMap.vue";

import {
  getSearchWeekObject,
  getTokenTC,
  setSearchWeekObject,
} from "../utils/sesions";

export default {
  components: {
    Header,
    Footer,
    ModalStripe,
    ModalStripeCheckout,
    Fancybox,
    GoogleMap,
  },
  // props: ["items"],
  data() {
    return {
      weekId: null,
      week: null,
      token: null,
      club: null,
      iso: null,
      member: null,
      numberRef: null,
      clientSecret: null,
      price: null,
      count: 5,
      msgBtn: null,
      timeBool: false,
      widthWindow: 300,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  async created() {
    // await this.checkSesion();
    await this.checkParams();
    await this.listWeek();

    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
      this.$forceUpdate();
    });

    this.$bus.on("confirm-pay", () => {
      this.showModalConfirm();
      this.$forceUpdate();
    });

    // setInterval(
    //   (__this) => {
    //     __this.timeBool = !__this.timeBool;
    //   },
    //   1500,
    //   this
    // );
    // console.log('window.innerWidth', window.innerWidth)
    this.widthWindow = window.innerWidth;
  },
  methods: {
    async checkParams() {
      const { weekId } = this.$route.query;
      this.weekId = weekId || null;
    },
    async listWeek() {
      if (this.weekId) {
        try {
          const { data } = await this.$axios.get(`/week/only/${this.weekId}`);
          this.week = data.week;
          this.price = data.week.Cost;
          // console.log(this.countries);
        } catch (error) {
          console.log(error);
        }
      } else this.$router.push("/");
    },
    getText(key, firstText = "") {
      if (this.week)
        return this.week[key] ? `${firstText} ${this.week[key]}` : "";
      else return "";
    },
    formatDate(date) {
      return this.$moment(date).format("ll");
    },
    getImg(position) {
      if (this.week)
        return (
          this.week.imgs[position] ||
          "https://dicesabajio.com.mx/wp-content/uploads/2021/06/no-image.jpeg"
        );
      else
        return "https://dicesabajio.com.mx/wp-content/uploads/2021/06/no-image.jpeg";
    },
    getTotalImg() {
      if (this.week) return this.week.imgs.length;
      else return 0;
    },
    getImgs() {
      if (this.week) return this.week.imgs;
      else return [];
    },
    getDescription() {
      if (this.week && this.week.resort)
        return this.iso === "en"
          ? this.week.resort?.HighlightsEN
          : this.week.resort?.HighlightsSP;
      else return "";
    },
    getAmenities() {
      if (this.week && this.week.resort)
        return this.week.resort.amenities || [];
      else return [];
    },
    async pay() {
      try {
        if (this.clientSecret) {
          this.$modal.show("pay");
        } else {
          /**
           * Reservamos
           */

          this.msgBtn = `${this.$func.parseIso("reserving")}...`;

          const payload = {
            client: {
              name: this.member.Nombre,
              lastname: this.member.ApellidoPaterno,
              email: this.member.Email || "",
              phone: this.member.Telefono || "",
              address: this.member.Direccion,
              city: this.member.Ciudad,
              state: this.member.Estado,
              zipcode: this.member.CodigoPostal,
              country: this.member.Pais,
              iso: this.member.Idioma === "EN" ? "en" : "es",
            },
            inventoryId: this.week.InventoryID,
            createdBy: "weeks",
          };

          const { data } = await this.$axios.post(`/week/hold`, {
            ...payload,
          });
          this.numberRef = data.numberRef;

          /**
           * Creamos el pago
           */

          this.msgBtn = `${this.$func.parseIso("startingStripe")}...`;

          const { data: payment } = await this.$axios.get(
            `/payment/create/payment-intent?numberRef=${this.numberRef}`
          );
          console.log("payment", payment);
          this.clientSecret = payment.clientSecret;

          await this.$forceUpdate();

          /**
           * Pasamos a Stripe
           */
          await this.$modal.show("pay");
        }
      } catch (error) {
        alert("Error al reservar o pagar");
        console.log(error);
      }
    },
    async payCheckout() {
      try {
        if (this.clientSecret) {
          this.$modal.show("pay-checkout");
        } else {
          /**
           * Reservamos
           */

          this.msgBtn = `${this.$func.parseIso("reserving")}...`;

          const payload = {
            client: {
              name: this.member.Nombre,
              lastname: this.member.ApellidoPaterno,
              email: this.member.Email || "",
              phone: this.member.Telefono || "",
              address: this.member.Direccion,
              city: this.member.Ciudad,
              state: this.member.Estado,
              zipcode: this.member.CodigoPostal,
              country: this.member.Pais,
              iso: this.member.Idioma === "EN" ? "en" : "es",
            },
            inventoryId: this.week.InventoryID,
            createdBy: "weeks",
          };

          const { data } = await this.$axios.post(`/week/hold`, {
            ...payload,
          });
          this.numberRef = data.numberRef;

          /**
           * Creamos el pago
           */

          this.msgBtn = `${this.$func.parseIso("startingStripe")}...`;

          // const { data: payment } = await this.$axios.get(
          //   `/payment/create/payment-checkout?numberRef=${this.numberRef}`
          // );
          // console.log("payment", payment);
          // this.clientSecret = `${payment.clientSecret}`;

          // await this.$forceUpdate();

          /**
           * Pasamos a Stripe
           */
          await this.$modal.show("pay-checkout");
        }
      } catch (error) {
        alert("Error al reservar o pagar");
        console.log(error);
      }
    },
    showModalConfirm() {
      this.$modal.show("confirm");
      setInterval((_this) => {
        _this.count--;
        if (_this.count <= 0) {
          clearInterval();
          _this.$router.push(`/?token=${token}`);
        }
      }, 1000),
        this;
    },
    goBook() {
      this.$bus.emit("footer-show", false);
      this.$router.push(`/resort-addtraveler?weekId=${this.weekId}`);
    },
    openGallery() {
      this.$refs.item1[0].click();
      // console.log(this.$refs.item1[0].click())
    },
  },
};
</script>

<style scoped>
:root {
  --s-color-brand-primary: #0472aa;
  --s-color-brand-primary-hsl-hue: 200;
  --s-color-brand-primary-hsl-saturation: 95%;
  --s-color-brand-primary-hsl-light: 34%;
  --s-color-brand-primary-red: 4;
  --s-color-brand-primary-green: 114;
  --s-color-brand-primary-blue: 170;

  --s-color-brand-dark: #00527c;
  --s-color-brand-dark-hsl-hue: 200;
  --s-color-brand-dark-hsl-saturation: 100%;
  --s-color-brand-dark-hsl-light: 24%;
  --s-color-brand-dark-red: 0;
  --s-color-brand-dark-green: 82;
  --s-color-brand-dark-blue: 124;

  --s-color-searchbox-background: #00527c;
  --s-color-searchbox-background-hsl-hue: 200;
  --s-color-searchbox-background-hsl-saturation: 100%;
  --s-color-searchbox-background-hsl-light: 24%;
  --s-color-searchbox-background-red: 0;
  --s-color-searchbox-background-green: 82;
  --s-color-searchbox-background-blue: 124;

  --s-color-searchbox-text: #393939;
  --s-color-searchbox-text-hsl-hue: 0;
  --s-color-searchbox-text-hsl-saturation: 0%;
  --s-color-searchbox-text-hsl-light: 22%;
  --s-color-searchbox-text-red: 57;
  --s-color-searchbox-text-green: 57;
  --s-color-searchbox-text-blue: 57;

  --s-color-footer-background: #00527c;
  --s-color-footer-background-hsl-hue: 200;
  --s-color-footer-background-hsl-saturation: 100%;
  --s-color-footer-background-hsl-light: 24%;
  --s-color-footer-background-red: 0;
  --s-color-footer-background-green: 82;
  --s-color-footer-background-blue: 124;

  --s-color-footer-text: #393939;
  --s-color-footer-text-hsl-hue: 0;
  --s-color-footer-text-hsl-saturation: 0%;
  --s-color-footer-text-hsl-light: 22%;
  --s-color-footer-text-red: 57;
  --s-color-footer-text-green: 57;
  --s-color-footer-text-blue: 57;
}

.noIcon {
  background: none;
}

.c-sb-input__field {
  padding-left: 40px;
}

#img-mobile {
  img:hover {
    opacity: 0.8;
    width: 500px;
  }
}
</style>
