/**
 *
 */

const data = require("./i18n_data");

const parseIso = (field) => {
  return data[field][localStorage.getItem("iso") || "es"];
};

module.exports = { parseIso };
