<template>
  <div>
    <div id="body" class="login language-en">
      <div id="wrapper" class="wrapper-login">
        <form
          id="headerForm"
          name="headerForm"
          method="post"
          class="headerform"
          enctype="application/x-www-form-urlencoded"
          data-partialsubmit="true"
        >
          <link
            href="https://booking.vacancyrewards.com/client-styles/vacancyrewards/vacancyrewards.css?version=1710522167490"
            rel="stylesheet"
            type="text/css"
          />
          <!--Header-->
          <!-- <HeaderLogin :isDefault="isDefault" :club="club" /> -->
          <!--Fin header-->
        </form>
        <div
          id="login-form"
          name="login-form"
          class="login-form-modal o-section--huge bg-clr--lighter-gray"
        >
          <div
            id="login-form:j_id_e"
            class="ui-blockui-content ui-widget ui-widget-content ui-corner-all ui-helper-hidden ui-shadow"
          ></div>
          <div class="login-form-modal-layout o-layout o-layout--center">
            <div
              class="o-layout__item bg-clr--white u-border-radius--big"
              style="max-width: 750px"
            >
              <div
                id="login-form:login-content:login-form-content"
                class="login-form"
              >
                <div
                  id="login-form:login-content:j_id_i"
                  class="ui-messages ui-widget"
                  aria-live="polite"
                  data-global="false"
                  data-summary="data-summary"
                  data-severity="all,error"
                  data-redisplay="true"
                ></div>
                <input
                  type="hidden"
                  name="micrositeId"
                  value="vacancyrewards"
                />
                <div
                  class="c-login-content o-layout o-layout--stretch u-position--relative"
                  style="min-height: 450px"
                >
                  <div
                    class="c-login-content__item c-login-content__item--first o-layout__item o-box o-box--small o-flexbox--generic-column u-3/5@md"
                  >
                    <div
                      class="c-modal-aside o-flexbox o-flexbox--generic-column u-flex--11 u-padding--null"
                    >
                      <div
                        class="c-modal-aside__content o-box o-box--bigger o-block o-flexbox o-flexbox--generic-column u-position--relative u-flex--11"
                      >
                        <div
                          class="c-login-content__img-wrapper c-modal-aside__img-wrapper"
                        >
                          <img
                            data-src="https://booking.vacancyrewards.com/javax.faces.resource/index-background/background-1.jpg"
                            :src="img"
                            data-index=""
                            data-error-src="https://booking.vacancyrewards.com/javax.faces.resource/images/no-photo-XS.jpg"
                            onerror="this.setAttribute('data-original-url', this.src); var errorUrl = this.getAttribute('data-error-src'); if(this.src !== errorUrl) {this.src = errorUrl};"
                            loading=""
                            alt=""
                            width=""
                            height=""
                            onclick=""
                            style="position: absolute"
                            class="lazy-img c-modal-aside__img u-image-object--cover u-image-object--full loaded"
                          />
                        </div>
                        <div
                          class="o-block__item u-font-size--giant u-line-height--11"
                        >
                          <b> {{ $func.parseIso("welcome") }} </b>
                          <p class="u-font-size--middle pt-5">
                            {{ $func.parseIso("loginToContinue") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="c-login-content__item o-layout__item u-2/5@md o-flexbox o-flexbox--generic-center"
                  >
                    <div
                      id="login-form:login-content:login:login-form-content"
                      class="login-form o-box--bigger--vertical o-box--default--horizontal o-block o-block--small u-1/1 dev-login-form-content"
                    >
                      <input
                        type="hidden"
                        name="micrositeId"
                        value="vacancyrewards"
                      /><input
                        type="hidden"
                        id="login-form:login-content:login:requestURI"
                        name="login-form:login-content:login:requestURI"
                        value="/"
                        data-request-uri="request-uri"
                      />

                      <div
                        id="login-form:login-content:login:travelcLoginPanel"
                        class="c-modal-login__form-acces o-block__item dev-travelcLoginPanel"
                      >
                        <div class="o-block">
                          <div class="o-block__item">
                            <b class="u-font-size--small">
                              {{
                                CLUBS_V2.includes(this.ClubId)
                                  ? $func.parseIso("loginIdOrEmail")
                                  : "LoginID"
                              }}
                            </b>
                            <div class="c-icon-escort">
                              <input
                                v-model="email"
                                id="login-form:login-content:login:Email"
                                name="login-form:login-content:login:Email"
                                type="text"
                                value=""
                                class="c-icon-escort__main c-text-input login-email-input"
                                placeholder="..."
                              />
                              <i
                                class="c-icon-escort__icon fa-regular fa-user"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          <div class="o-block__item">
                            <b class="u-font-size--small">
                              {{ $func.parseIso("password") }}
                            </b>
                            <div class="c-icon-escort">
                              <span
                                class="ui-password ui-password-masked ui-inputwrapper ui-input-icon-right"
                                style="cursor: pointer"
                              >
                                <input
                                  v-model="password"
                                  id="login-form:login-content:login:j_password"
                                  name="login-form:login-content:login:j_password"
                                  :type="isShowPass ? 'text' : 'password'"
                                  class="ui-inputfield ui-widget ui-state-default ui-corner-all c-icon-escort__main c-text-input login-password-input"
                                  autocomplete="current-password"
                                  placeholder="..." />
                                <i
                                  id="login-form:login-content:login:j_password_mask"
                                  class="ui-password-icon"
                                  @click="showPass"
                                >
                                </i
                              ></span>
                            </div>
                          </div>
                          <!-- <div class="o-block__item">
                            <div
                              class="o-flexbox o-group--small u-align-items--center"
                            >
                              <label
                                class="c-point-checkbox"
                                for="login-form:login-content:login:remember"
                                ><input
                                  id="login-form:login-content:login:remember"
                                  type="checkbox"
                                  name="login-form:login-content:login:remember"
                                  checked="checked"
                                  value="true"
                                  class="c-point-checkbox__input" />
                                <span class="c-point-checkbox__container"
                                  ><span
                                    class="c-point-checkbox__point"
                                  ></span></span
                              ></label>
                              <span class="u-font-size--small"
                                >Remember me</span
                              >
                            </div>
                          </div> -->
                          <div class="o-block__item">
                            <button
                              id="login-form:login-content:login:signin"
                              name="login-form:login-content:login:signin"
                              :class="`ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only o-block__item c-button bg-clr--brand u-1/1 tc-bt-signin signin-button ${
                                (loadingLogin && 'button--loading') || ''
                              }`"
                              aria-label=""
                              @click="login"
                              :style="
                                btnColor
                                  ? `background-color: #${btnColor};`
                                  : ''
                              "
                            >
                              <span
                                class="ui-button-text ui-c"
                                :style="
                                  (loadingLogin && `color: #${btnColor};`) || ''
                                "
                                >{{ $func.parseIso("login") }}</span
                              >
                            </button>
                          </div>
                          <div class="o-block__item"></div>
                          <div
                            class="o-block__item login-help"
                            style="cursor: pointer"
                            @click="goToRecoverPassword"
                          >
                            <a class="u-font-size--small"
                              ><b
                                >{{ $func.parseIso("forgotYourPassword") }}
                              </b></a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input type="hidden" id="microsite" value="vacancyrewards" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="j_id_c_3u:cookiesPolicy"
        class="c-modal cookiesPolicy"
        data-size="medium"
        role="dialog"
        data-closeable="false"
        tabindex="-1"
        aria-modal="true"
        data-active="false"
      >
        <div
          class="c-modal__backmask"
          onclick="$('.cookiesPolicy').modal('hide')"
        ></div>
        <div class="c-modal__wrapper">
          <div class="c-modal__container">
            <div class="c-modal__close" style="">
              <button
                type="button"
                class="c-modal__close-btn"
                data-dismiss="modal"
              >
                <span
                  class="o-box--small--horizontal o-box--tiny--vertical u-font-weight--500 u-font-size--small u-border-radius--sm u-line-height--1 u-pointer-events--none"
                >
                  Close
                  <i
                    class="fa-solid fa-caret-right u-font-size--default"
                  ></i></span
                ><i class="u-display--block fa-regular fa-close"></i>
              </button>
            </div>
            <div class="c-modal__body">
              <div
                class="c-modal__header u-font-weight--600 u-line-height--1 u-hide-empty"
              >
                Cookies policy
              </div>
              <form
                id="j_id_c_3u:cookies-consent-form"
                name="j_id_c_3u:cookies-consent-form"
                method="post"
                action="/login.xhtml"
                class="c-modal-cookies-consent"
                enctype="application/x-www-form-urlencoded"
              >
                <div id="box-cookies-main" class="box-cookies-consent">
                  <div class="o-block">
                    <div class="o-block__item">
                      We use first-party and third-party cookies (Google and
                      Facebook) to analyze the usage of our website, show you
                      tailored ads according your browsing habits and improve
                      browsing performance. You can read more about our<a
                        id="j_id_c_3u:cookies-consent-form:goPolicies"
                        href="#"
                        class="ui-commandlink ui-widget clr--brand u-font-weight--bold dev-go-cookies-details"
                        aria-label=""
                      >
                        Cookies policy
                      </a>
                      <a
                        id="j_id_c_3u:cookies-consent-form:goSettings"
                        href="#"
                        class="ui-commandlink ui-widget clr--brand u-font-weight--bold dev-go-cookies-settings"
                        aria-label=""
                      >
                        here
                      </a>
                    </div>
                    <div
                      class="o-block o-block--small o-layout o-layout--gutter-small o-block--item u-text-align--center u-text-align--right@sm"
                    >
                      <div
                        class="o-block__item o-layout__item u-display--inline"
                      >
                        <input
                          id="j_id_c_3u:cookies-consent-form:rejectAllMain"
                          name="j_id_c_3u:cookies-consent-form:rejectAllMain"
                          type="button"
                          value="Reject all"
                          onclick="rejectAllCookies()"
                          class="c-button bg-clr--brand--alpha-soft dev-reject-all"
                        />
                      </div>
                      <div
                        class="o-block__item o-layout__item u-display--inline u-text-align--right"
                      >
                        <input
                          id="j_id_c_3u:cookies-consent-form:acceptAllMain"
                          name="j_id_c_3u:cookies-consent-form:acceptAllMain"
                          type="button"
                          value="Accept all"
                          onclick="acceptAllCookies()"
                          class="c-button bg-clr--brand dev-accept-all mb-5 mb-0@sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  type="hidden"
                  name="j_id_c_3u:cookies-consent-form_SUBMIT"
                  value="1"
                /><input
                  type="hidden"
                  name="javax.faces.ViewState"
                  id="j_id__v_0:javax.faces.ViewState:5"
                  value="ZjAxYTZkNWIxMDRjMjMyYzAwMDAwMDAy"
                  autocomplete="off"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        id="j_id_c_68:privacyPolicy"
        class="c-modal privacyPolicy c-modal-cookies-privacy"
        data-size="large"
        role="dialog"
        data-closeable="true"
        tabindex="-1"
        aria-modal="true"
        data-active="false"
      >
        <div
          class="c-modal__backmask"
          onclick="$('.privacyPolicy').modal('hide')"
        ></div>
        <div class="c-modal__wrapper">
          <div class="c-modal__container">
            <div class="c-modal__close" style="">
              <button
                type="button"
                class="c-modal__close-btn"
                data-dismiss="modal"
              >
                <span
                  class="o-box--small--horizontal o-box--tiny--vertical u-font-weight--500 u-font-size--small u-border-radius--sm u-line-height--1 u-pointer-events--none"
                >
                  Close
                  <i
                    class="fa-solid fa-caret-right u-font-size--default"
                  ></i></span
                ><i class="u-display--block fa-regular fa-close"></i>
              </button>
            </div>
            <div class="c-modal__body">
              <div
                class="c-modal__header u-font-weight--600 u-line-height--1 u-hide-empty"
              >
                Privacy Policy
              </div>
              <div id="privacy_policy" class="privacy_policy">
                <p style="text-align: justify">
                  Vacancy Rewards LLC, (“we”, “us”, or “Vacancy Rewards”) values
                  you as our customer and recognizes that privacy is important
                  to you. This Privacy Statement explains how we collect, use,
                  and disclose data when you use our platform and associated
                  services, and tells you how to contact us.
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>Privacy Statement Summary</strong>
                </p>
                <p style="text-align: justify">
                  This is a summary of our Privacy Statement. To review our
                  Privacy Statement in full, please scroll down.
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>What does this Privacy Statement cover?</strong>
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong
                    >This Privacy Statement is designed to describe:</strong
                  >
                </p>
                <p style="text-align: justify">
                  -How and what type of personal information we collect and use
                </p>
                <p style="text-align: justify">
                  -When and with whom we share your personal information
                </p>
                <p style="text-align: justify">
                  -What choices you can make about how we collect, use, and
                  share your personal
                </p>
                <p style="text-align: justify">
                  information What personal information do we collect and use,
                  and how do we collect it?
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>We collect personal information when:</strong>
                </p>
                <p style="text-align: justify">-You give us the information</p>
                <p style="text-align: justify">-We collect it automatically</p>
                <p style="text-align: justify">-We receive it from others</p>
                <p style="text-align: justify">
                  When you create an account on one of our sites, sign up to
                  receive offers or information, or make a booking using our
                  platform, you give us your personal information. We also
                  collect such information through automated technology such as
                  cookies placed on your browser, with your consent where
                  applicable, when you visit our sites or download and use our
                  Apps. We also receive information from business partners and
                  other third parties, which help us improve our platform and
                  associated tools and services, update and maintain accurate
                  records, potentially detect and investigate fraud, and more
                  effectively market our services.
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>How is your personal information shared?</strong>
                </p>
                <p style="text-align: justify">
                  Your personal information may be shared to help you book your
                  travel and/or vacation, assist with your travel and/or
                  vacation stay, communicate with you (including when we send
                  information on products and services or enable you to
                  communicate with travel
                </p>
                <p style="text-align: justify">
                  providers and/or property owners), and comply with the law.
                  The full Privacy Statement details how personal information is
                  shared.
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>What are your rights and choices?</strong>
                </p>
                <p style="text-align: justify">
                  You can exercise your data protection rights in various ways.
                  For example, you can opt-out of marketing by clicking the
                  “unsubscribe” link in the emails, in your account as
                  applicable, or contacting our customer service. Our Privacy
                  Statement has more information about the options and data
                  protection rights available to you.
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>How to contact us</strong>
                </p>
                <p style="text-align: justify">
                  More information about our privacy practices is in our full
                  Privacy Statement. You can also contact us as described below
                  in the “Contact Us” section to ask questions about how we
                  handle your personal information or make requests about your
                  personal information.
                </p>
                <p style="text-align: justify">
                  <strong>Privacy Statement</strong>
                </p>
                <p style="text-align: justify">
                  <strong
                    >Categories of Personal Information We Collect When you use
                    our platform, Apps, or associated tools or services, we may
                    collect the following kinds of personal information from you
                    as needed:</strong
                  >
                </p>
                <p style="text-align: justify">
                  -Name, email address, telephone number, and home, business,
                  and billing addressees.
                </p>
                <p style="text-align: justify">
                  -Government-issued Identification required for booking or
                  identity verification, such as passport, driver’s license, and
                  government redress numbers, and vacation property owners, tax
                  identification number
                </p>
                <p style="text-align: justify">
                  -Payment information such as payment card number, expiration
                  date, and billing address
                </p>
                <p style="text-align: justify">
                  -Travel-related preferences and requests such as favorite
                  destination accommodation types, and special dietary and
                  accessibility needs, as available and
                </p>
                <p style="text-align: justify">
                  -Loyalty program and membership information
                </p>
                <p style="text-align: justify">-Birth date and gender</p>
                <p style="text-align: justify">-Geolocation</p>
                <p style="text-align: justify">
                  -Images, videos, and other recordings
                </p>
                <p style="text-align: justify">
                  -Social media account ID and other publicly available
                  information
                </p>
                <p style="text-align: justify">
                  -Communications with us (such as recordings of calls with
                  customer service
                </p>
                <p style="text-align: justify">
                  representatives for quality assurance and training purposes)
                  -Searches you conduct, transactions, and other interactions
                  with you on our online
                </p>
                <p style="text-align: justify">services and Apps</p>
                <p style="text-align: justify">
                  -Other communications that occur through the platform among
                  partners and travelers, and in-group chat and
                  traveler-collaboration tools
                </p>
                <p style="text-align: justify">
                  -The searches and transactions conducted through the platform
                </p>
                <p style="text-align: justify">
                  -Data you give us about other people, such as your travel
                  companions or others for whom you are making a booking
                </p>
                <p style="text-align: justify">
                  -Information we receive about you from third parties such as
                  our business and affiliate partners and authorized service
                  providers which may include updated contact information,
                  demographic information, interests, and purchase history,
                  which we may add to your account or profile and use for market
                  research and analysis
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong
                    >When you install any of our apps or use our platform, we
                    automatically collect the following types of information
                    from your device:</strong
                  >
                </p>
                <p style="text-align: justify">-IP address</p>
                <p style="text-align: justify">-Device type</p>
                <p style="text-align: justify">
                  -Unique device identification numbers
                </p>
                <p style="text-align: justify">
                  -Internet browser-type (such as Firefox, Safari, Chrome, and
                  Internet Explorer)
                </p>
                <p style="text-align: justify">-Internet Service Provider</p>
                <p style="text-align: justify">-Operating System</p>
                <p style="text-align: justify">-Mobile carrier</p>
                <p style="text-align: justify">
                  -How your device has interacted with our online services,
                  including the pages accessed, links clicked, trips viewed, and
                  features used, along with associated dates and times
                </p>
                <p style="text-align: justify">
                  -Details of any referring website or exit pages, as well as
                  general geographic location (such as at the country or
                  city-level)
                </p>
                <p style="text-align: justify">
                  Our Cookie Statement further explains how we use cookies and
                  similar tracking technology.
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>Mobile Applications</strong>
                </p>
                <p style="text-align: justify">
                  <strong
                    >When you download and use any of our mobile apps, we
                    collect certain technical information from your device to
                    enable the app to work properly and as otherwise described
                    in this Privacy Statement. That technical information
                    includes:</strong
                  >
                </p>
                <p style="text-align: justify">
                  -Device and telephone connectivity information such as your
                  carrier, network type, network operator, subscriber identity
                  module (“SIM”) operator, and SIM country
                </p>
                <p style="text-align: justify">-Operating system and version</p>
                <p style="text-align: justify">-Device model</p>
                <p style="text-align: justify">-Performance and data usage</p>
                <p style="text-align: justify">
                  -Usage data, such as dates and times the app accesses our
                  servers, the features and links clicked in the app, searches,
                  transactions, and the data and files downloaded to the app
                </p>
                <p style="text-align: justify">
                  -Device settings selected or enabled, such as Wi-Fi, Global
                  Positioning System (“GPS”), and Bluetooth (which may be used
                  for location services, subject to your permission as explained
                  below)
                </p>
                <p style="text-align: justify">-Mobile device</p>
                <p style="text-align: justify">
                  -Other technical information such as app name, type, and
                  version as needed to provide you with services
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>Permissions for Location-Based Services:</strong>
                </p>
                <p style="text-align: justify">
                  Depending on your device’s settings and permissions and your
                  choice to participate in certain programs, we may collect the
                  location of your device by using GPS signals, cell phone
                  towers, Wi-Fi signals, Bluetooth, or other technologies. We
                  will collect this information, if you opt-in through the app
                  or other program (either during your initial login or later)
                  to enable certain location-based services available within the
                  app (for example, locating available lodging closest to you).
                  To disable the location capabilities of the app, you can log
                  off or change your mobile device’s settings.
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>Use of Personal Information</strong>
                </p>
                <p style="text-align: justify">
                  We use your personal information for various purposes
                  described below, which depend on the site you visit or the app
                  you use.
                </p>
                <p style="text-align: justify">
                  <strong>Your Use of Online Sites, Apps, and Services:</strong>
                </p>
                <p style="text-align: justify">
                  -Book the requested travel or enable vacation property booking
                </p>
                <p style="text-align: justify">
                  -Provide services related to the booking and/or account
                </p>
                <p style="text-align: justify">
                  -Create, maintain, and update user accounts on our platform
                  and authenticate you as a user
                </p>
                <p style="text-align: justify">
                  -Maintain your search and travel history, accommodation and
                  travel preferences, and similar information about your use of
                  Vacancy Rewards’s platform and services, as otherwise
                  described in this Privacy Statement
                </p>
                <p style="text-align: justify">
                  -Enable and facilitate acceptance and processing of payments,
                  coupons, and other
                </p>
                <p style="text-align: justify">transactions</p>
                <p style="text-align: justify">
                  -Administer loyalty and rewards programs
                </p>
                <p style="text-align: justify">
                  -Collect and enable booking-related reviews
                </p>
                <p style="text-align: justify">
                  -Help you to use our services faster and easier through
                  features like the ability to sign in using your account within
                  the online services and sites of some of the Vacancy
                </p>
                <p style="text-align: justify">Rewards companies</p>
                <p style="text-align: justify">Communications and Marketing:</p>
                <p style="text-align: justify">
                  -Respond to your questions, requests for information, and
                  process information choices
                </p>
                <p style="text-align: justify">
                  -Enable communication between you and the travel supplier like
                  hotels and vacation
                </p>
                <p style="text-align: justify">property owners</p>
                <p style="text-align: justify">
                  -Contact you (such as by text message, email, phone calls,
                  mail, push notifications, or messages on other communication
                  platforms) to provide information like travel booking
                  confirmations and updates, for marketing purposes, or other
                  purposes as described in this Privacy Statement
                </p>
                <p style="text-align: justify">
                  -Market our products and services, optimize such marketing to
                  be more relevant to you and measure and analyze the
                  effectiveness of our marketing and promotions
                </p>
                <p style="text-align: justify">
                  -Administer promotions like contests, sweepstakes, and similar
                  giveaways
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>Other Business Purposes and Compliance</strong>
                </p>
                <p style="text-align: justify">
                  -Conduct surveys, market research, and data analytics
                </p>
                <p style="text-align: justify">
                  -Maintain, improve, research, and measure the effectiveness of
                  our sites and apps, activities, tools, and services
                </p>
                <p style="text-align: justify">
                  -Monitor or record calls, chats, and other communications with
                  our customer service team and other representatives, as well
                  as platform communications between or among partners and
                  travelers for quality control, training, dispute resolution,
                  and as described in this Privacy Statement
                </p>
                <p style="text-align: justify">
                  -Create aggregated or otherwise anonymized or de-identified
                  data, which we may use and disclose without restriction where
                  permissible
                </p>
                <p style="text-align: justify">
                  -Promote security, verify the identity of our customers,
                  prevent and investigate fraud and unauthorized activities,
                  defend against claims and other liabilities, and manage other
                  risks
                </p>
                <p style="text-align: justify">
                  -Comply with applicable laws, protect our and our users’
                  rights and interest, defend ourselves, and respond to law
                  enforcement, other legal authorities, and requests that are
                  part of a legal process
                </p>
                <p style="text-align: justify">
                  -Comply with applicable security and anti-terrorism,
                  anti-bribery, customs and
                </p>
                <p style="text-align: justify">
                  immigration, and other such due diligence laws and
                  requirements -Operate our business using lawful business
                  purposes and as permitted by law Sharing of Personal
                  Information
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong
                    >We share your personal information as described below and
                    in this Privacy Statement, and as permitted by applicable
                    law.</strong
                  >
                </p>
                <p style="text-align: justify">
                  -Third-party service providers. We share personal information
                  with third parties in connection with the delivery of services
                  to you and the operation of our business (for example, to
                  provide credit card processing, customer service, business
                  analytics, and fraud prevention and compliance services, and
                  to serve you with advertising tailored to your interests).
                  These third-party service providers are required to protect
                  the personal information we share with them and may not use
                  any directly identifying personal information other than to
                  provide services we contracted them for. They are not allowed
                  to use the personal information we share for purposes of their
                  direct marketing (unless you have separately consented with
                  the third party under the terms provided by the third party).
                </p>
                <p style="text-align: justify">
                  -Travel suppliers. We share personal information with
                  travel-related suppliers such as hotels, insurance, vacation
                  rental property owners, and managers. Please note that travel
                  suppliers may contact you to obtain additional information if
                  and as required to facilitate your booking or to otherwise
                  provide the travel or associated services.
                </p>
                <p style="text-align: justify">
                  -Business partners and offers. If we promote a program or
                  offer a service or product in conjunction with a third-party
                  business partner, we will share your information with that
                  partner to assist in marketing or to provide the associated
                  product or service. In most of those cases, the program or
                  offer will include the name of the third-party business
                  partner, either alone or with ours, or you will be redirected
                  to the website of that business with notice. An example of
                  such a business-partner relationship would be a third-party
                  loyalty program for which you could earn points by completing
                  a booking on our platform.
                </p>
                <p style="text-align: justify">
                  -Other Third-Parties. When you access certain features like
                  Facebook’s “Like” button or a single sign-on that allows you
                  to login with your social media credentials to our online
                  services, you will share information with the third-party,
                  like a social media company, such as the fact that you have
                  visited or interacted with us.
                </p>
                <p style="text-align: justify">
                  -Legal rights and obligations. We may disclose your personal
                  information to enforce our policies, or where we are permitted
                  (or believe in good faith that we are required) to do so by
                  applicable law, such as in response to a request by law
                  enforcement or governmental authority, in connection with
                  actual or proposed litigation, or to protect and defend our
                  property, people and other rights or interests. We may also
                  share your personal information under a subpoena or other
                  legal request, or as necessary to remit certain taxes in the
                  course of processing payments as required by law or legal
                  process. -Corporate We may share your personal information in
                  connection with a corporate transaction, such as a
                  divestiture, merger, consolidation, assignments, or asset
                  sale, or the unlikely event of bankruptcy. In the case of any
                  acquisition, we will inform the
                </p>
                <p style="text-align: justify">
                  buyer it must use your personal information only for the
                  purposes disclosed in this Privacy Statement.
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>Your Rights and Choices</strong>
                </p>
                <p style="text-align: justify">
                  You have certain rights and choices concerning your personal
                  information, as described below:
                </p>
                <p style="text-align: justify">
                  -You can control our use of certain cookies by following the
                  guidance in our Cookie Statement
                </p>
                <p style="text-align: justify">
                  -You can update the accuracy of your information at any time
                  by either logging into your account or contacting us
                </p>
                <p style="text-align: justify">
                  -If you no longer wish to receive marketing and promotional
                  emails, you may unsubscribe by clicking the ‘unsubscribe’ link
                  in the email.
                </p>
                <p style="text-align: justify">
                  -For our mobile apps, you can view and manage notifications
                  and preferences in the settings menus of the app and your
                  operating system
                </p>
                <p style="text-align: justify">
                  -If we are processing your personal information based on
                  consent, you may withdraw that consent at any time by
                  contacting us. Withdrawing your consent will not affect the
                  lawfulness of any processing that occurred before you withdrew
                  consent and it will not affect our processing of your personal
                  information that is conducted in reliance on a legal basis
                  other than consent
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong
                    >For questions about privacy and your rights and choices,
                    please contact us here. For California residents:</strong
                  >
                </p>
                <p style="text-align: justify">
                  California residents may exercise the following rights: -To
                  request a copy of your personal information
                </p>
                <p style="text-align: justify">
                  -To request disclosure of personal information sold or
                  disclosed for a business purpose
                </p>
                <p style="text-align: justify">
                  -To request the deletion of personal information
                </p>
                <p style="text-align: justify">
                  -To opt-out of the sale of personal informationvn
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>International Data Transfer</strong>
                </p>
                <p style="text-align: justify">
                  The personal information we process may be transmitted or
                  transferred to countries other than the country in which you
                  reside. Those countries may have data protection laws that are
                  different from the laws of your country. The servers for our
                  platform are located in the United States, and third-party
                  service providers operate in many countries around the world.
                  When we collect your personal information, we may process it
                  in any of those countries.
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  We have taken appropriate steps and put safeguards in place to
                  help ensure that your personal information remains protected
                  by this Privacy Statement. We also require that third-party
                  service providers to whom a data transfer is made have
                  appropriate safeguards in place to protect your personal
                  information, in compliance with applicable data protection
                  law. The particular measures used will depend on the service
                  provider, and our agreements with them may include European
                  Commission- approved Standard Contractual Clauses, the service
                  provider’s certification under the EU-U.S. and/or Swiss-U.S.
                  Privacy Shield certification, or reliance on the service
                  provider’s Binding Corporate Rules, as defined by the European
                  Commission.
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify"><strong>Security</strong></p>
                <p style="text-align: justify">
                  We want you to feel confident about using our platform and all
                  associated tools and services, and we are committed to taking
                  appropriate steps to protect the information we collect. While
                  no company can guarantee absolute security, we do take
                  reasonable steps to implement appropriate physical, technical,
                  and organizational measures to protect the personal
                  information that we collect and process.
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong>Record Retention</strong>
                </p>
                <p style="text-align: justify">
                  We will retain your personal information by all applicable
                  laws, for as long as it may be relevant to fulfill the
                  purposes outlined in this Privacy Statement unless a longer
                  retention period is required or permitted by law. We will
                  deidentify, aggregate, or otherwise anonymize your personal
                  information if we intend to use it for analytical purposes or
                  trend analysis over longer periods.
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify">
                  <strong
                    >The criteria we use to determine our retention periods
                    include:</strong
                  >
                </p>
                <p style="text-align: justify">
                  -The duration of our relationship with you, including any open
                  accounts you may have with Vacancy Rewards, recent bookings,
                  or other transactions you have made on our platform
                </p>
                <p style="text-align: justify">
                  -Whether we have a legal obligation related to your personal
                  information, such as laws requiring us to keep records of your
                  transactions with us
                </p>
                <p style="text-align: justify">
                  -Whether there are any current and relevant legal obligations
                  affecting how long we will keep your personal information,
                  including contractual obligations, litigation holds, statutes
                  of limitations, and regulatory investigations
                </p>
                <p style="text-align: justify"><br /></p>
                <p style="text-align: justify"><strong>Contact Us</strong></p>
                <p style="text-align: justify">
                  If you have any questions or concerns about our use of your
                  personal information, please contact us. We may update this
                  Privacy Statement in response to changing laws or technical or
                  business developments. You can see when this Privacy Statement
                  was last updated by checking the “last updated” date displayed
                  at the top of this Statement.
                </p>
                <p><br /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer :isDefault="isDefault" :club="club" /> -->
    <Alert :show="false" />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import HeaderLogin from "../components/HeaderLogin.vue";
import Alert from "../components/Alert.vue";
import axios from "axios";
const { VUE_APP_TC_URL } = process.env;
import { getClubObject, setTokenTCAndExpireToken } from "../utils/sesions";
const CLUBS_V2 = [
  "96213bc2-b460-414e-980a-d0bd99d0869c",
  "f908ec94-2a57-4478-a330-9104af639e42",
  "22c92470-937f-48dc-87d3-9baa06447628",
  "b1ccd89f-f90f-470f-9ffb-acbe5f082df8",
];
const IMAGES_LOGIN = [
  "https://booking.vacancyrewards.com/javax.faces.resource/index-background/background-0.jpg",
  "https://booking.vacancyrewards.com/javax.faces.resource/index-background/background-2.jpg",
  "https://booking.vacancyrewards.com/javax.faces.resource/index-background/background-5.jpg",
  "https://booking.vacancyrewards.com/javax.faces.resource/index-background/background-1.jpg",
];

export default {
  data() {
    return {
      email: null,
      password: null,
      iso: "en",
      show: false,
      club: null,
      isDefault: true,
      isShowPass: false,
      UrlTC: null,
      MicrositeTC: null,
      ClubId: null,
      CLUBS_V2,
      IMAGES_LOGIN,
      img: "https://booking.vacancyrewards.com/javax.faces.resource/index-background/background-0.jpg",
      btnColor: null,
      loadingLogin: false,
    };
  },
  components: {
    Footer,
    HeaderLogin,
    Alert,
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
      this.$forceUpdate();
    });
    // await this.checkClub();
    this.img = this.getImgs();

    const __club = getClubObject();
    if (__club?.ColorPrimarioHex) this.btnColor = __club?.ColorPrimarioHex;
  },
  async updated() {
    // this.show = true;
    // this.$forceUpdate();
    await this.checkClub();
  },
  methods: {
    getImgs() {
      const random = (mn, mx) => {
        return Math.random() * (mx - mn) + mn;
      };
      return IMAGES_LOGIN[Math.floor(random(1, 4)) - 1];
    },
    changeIso() {
      if (this.iso === "es") {
        localStorage.setItem("iso", "en");
        this.iso = "en";
      } else {
        localStorage.setItem("iso", "es");
        this.iso = "es";
      }
    },
    async login() {
      try {
        this.loadingLogin = true
        console.log("3. this.ClubId", this.ClubId);
        let urlLogin = "";

        if (CLUBS_V2.includes(this.ClubId)) urlLogin = "/members/login";
        else urlLogin = "/members/login/alphazulu";

        const { data } = await this.$axios.post(urlLogin, {
          email: this.email,
          password: this.password,
          clubId: this.ClubId || "all",
        });
        // console.log(this.club);

        setTokenTCAndExpireToken(data?.token, data?.exp, data?.sesion);
        const __MicrositeTC__ = data?.MicrositeTC || this.MicrositeTC;
        const __UrlTC__ = data?.UrlTC || this.UrlTC;
        window.location.href = `https://${__UrlTC__}?submit=true&keepurl=true&token=${data?.token}&microsite=${__MicrositeTC__}`;
        this.loadingLogin = false
        // window.location.href = `https://${this.club.UrlWeek}?submit=true&keepurl=true&token=${data?.token}&microsite=${this.club.MicrositeTC}`;

        // const tmp = "dev2.vacancyrewards.com";
        // window.location.href = `https://${tmp}?submit=true&keepurl=true&token=${data?.token}&microsite=${this.club.MicrositeTC}`;

        // window.location.href = `http://localhost:8081?submit=true&keepurl=true&token=${data?.token}&microsite=${this.club.MicrositeTC}`;
      } catch (error) {
        this.loadingLogin = false
        console.log(error);
        alert(error?.response?.data?.message);
      }
    },
    checkClub() {
      return new Promise((resolve, reject) => {
        // try {
        //   const { data } = await this.$axios.get(`/auth/club`);
        //   this.club = data.club;
        //   this.isDefault = data.default;
        // } catch (error) {
        //   console.log(error);
        //   // alert(error?.response?.data?.message);
        // }
        console.log("2. checkClub");
        const { UrlTC, MicrositeTC, Id } = getClubObject();
        // console.log("UrlTC", UrlTC);
        // console.log("MicrositeTC", MicrositeTC);
        // console.log("Id", Id);
        // console.log("getClubObject()", getClubObject());
        this.UrlTC = UrlTC;
        this.MicrositeTC = MicrositeTC;
        this.ClubId = Id;
        return resolve();
      });
    },
    showPass() {
      this.isShowPass = !this.isShowPass;
    },
    goToRecoverPassword() {
      this.$router.push("/recover-password");
    },
  },
};
</script>

<style scoped>
.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>

<style scoped>
:root {
  --s-color-brand-primary: #0472aa;
  --s-color-brand-primary-hsl-hue: 200;
  --s-color-brand-primary-hsl-saturation: 95%;
  --s-color-brand-primary-hsl-light: 34%;
  --s-color-brand-primary-red: 4;
  --s-color-brand-primary-green: 114;
  --s-color-brand-primary-blue: 170;

  --s-color-brand-dark: #00527c;
  --s-color-brand-dark-hsl-hue: 200;
  --s-color-brand-dark-hsl-saturation: 100%;
  --s-color-brand-dark-hsl-light: 24%;
  --s-color-brand-dark-red: 0;
  --s-color-brand-dark-green: 82;
  --s-color-brand-dark-blue: 124;

  --s-color-searchbox-background: #00527c;
  --s-color-searchbox-background-hsl-hue: 200;
  --s-color-searchbox-background-hsl-saturation: 100%;
  --s-color-searchbox-background-hsl-light: 24%;
  --s-color-searchbox-background-red: 0;
  --s-color-searchbox-background-green: 82;
  --s-color-searchbox-background-blue: 124;

  --s-color-searchbox-text: #393939;
  --s-color-searchbox-text-hsl-hue: 0;
  --s-color-searchbox-text-hsl-saturation: 0%;
  --s-color-searchbox-text-hsl-light: 22%;
  --s-color-searchbox-text-red: 57;
  --s-color-searchbox-text-green: 57;
  --s-color-searchbox-text-blue: 57;

  --s-color-footer-background: #00527c;
  --s-color-footer-background-hsl-hue: 200;
  --s-color-footer-background-hsl-saturation: 100%;
  --s-color-footer-background-hsl-light: 24%;
  --s-color-footer-background-red: 0;
  --s-color-footer-background-green: 82;
  --s-color-footer-background-blue: 124;

  --s-color-footer-text: #393939;
  --s-color-footer-text-hsl-hue: 0;
  --s-color-footer-text-hsl-saturation: 0%;
  --s-color-footer-text-hsl-light: 22%;
  --s-color-footer-text-red: 57;
  --s-color-footer-text-green: 57;
  --s-color-footer-text-blue: 57;
}
</style>
