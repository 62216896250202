import Vue from "vue";
import App from "./App.vue";
import login from "./router/login";
import weeks from "./router/weeks";
// import i18n from '@/i18n/i18n'
import { apiAxios } from "./utils/axios";
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import store from './store'
import moment from "moment";
import i18n from "./utils/i18n";
import VueBus from "vue-bus";
import vmodal from "vue-js-modal";

Vue.use(VueBus);
Vue.use(vmodal);

// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
Vue.config.productionTip = false;

Vue.prototype.$func = i18n;
Vue.prototype.$axios = apiAxios;
// Vue.prototype.$store = store
Vue.prototype.$moment = moment;
// Vue.use(i18n);

// i18n.locale = "es";
const mode = process.env.VUE_APP_MODE || "login";

new Vue({
  router: mode === "login" ? login : weeks,
  // i18n,
  // store,
  render: (h) => h(App),
}).$mount("#app");
