<template>
  <div>
    <Header />
    <div class="main">
      <div
        class="container o-container o-container--large booking-process reserva"
        id="RESERVA"
        role="main"
      >
        <div
          id="breadcrumbs-form"
          name="breadcrumbs-form"
          data-partialsubmit="true"
        >
          <nav class="navbar navegacion2" role="navigation" id="navegacion2">
            <div
              id="j_id_2v:breadcrumbs-availability"
              class="wrapperNavbar breadcrumbs-availability"
            ></div>
            <div id="breadcrumb-bottom"></div>
          </nav>
          <input
            type="hidden"
            name="breadcrumbs-form_SUBMIT"
            value="1"
            autocomplete="off"
          /><input
            type="hidden"
            name="javax.faces.ViewState"
            id="j_id__v_0:javax.faces.ViewState:2"
            value="ZTMyZWRmOTlkZDczM2Y1YTAwMDAwMDA5"
            autocomplete="off"
          />
        </div>
        <div class="o-layout">
          <div
            id="col-left"
            class="o-layout__item u-float--left u-12/12 u-3/12@md"
          >
            <div class="col-left--box">
              <div
                id="comp-summary"
                class="ongoing hidden-print comp-summary only-hotel"
              >
                <nav
                  class="float-breadcrumb navbar o-position o-position--above o-position--fixed o-position--top u-1/1"
                  role="navigation"
                  id="navegacion"
                  style="display: none; z-index: 1001"
                >
                  <header class="nav-brochure">
                    <div
                      id="j_id_4v:breadcrumbs-availability"
                      class="wrapperNavbar breadcrumbs-availability"
                    ></div>
                  </header>
                </nav>
                <div class="c-fast-confirm mb-10 true">
                  <div
                    class="c-fast-confirm__heading o-layout o-layout--stretch o-layout--stretch--middle"
                  >
                    <b class="u-font-weight--600">
                      {{ $func.parseIso("resortSummary") }}</b
                    >
                  </div>
                  <div
                    class="c-fast-confirm__hero c-fast-confirm__hero--destination"
                  >
                    <div class="c-fast-confirm__hero__wrapper-img">
                      <img
                        data-src="https://cdn5.travelconline.com/unsafe/fit-in/0x200/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FDydSA3d4NdjY-2CNsl1uTcl4DDyY.jpeg"
                        src="https://cdn5.travelconline.com/unsafe/fit-in/0x200/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FDydSA3d4NdjY-2CNsl1uTcl4DDyY.jpeg"
                        data-index=""
                        data-error-src="/javax.faces.resource/images/no-photo-XS.jpg"
                        onerror="this.setAttribute('data-original-url', this.src); var errorUrl = this.getAttribute('data-error-src'); if(this.src !== errorUrl) {this.src = errorUrl};"
                        loading="lazy"
                        alt="Miami FL"
                        width=""
                        height=""
                        onclick=""
                        style=""
                        class="lazy-img c-fast-confirm__hero__img loaded"
                        data-ll-status="loaded"
                      />
                    </div>
                    <div
                      class="c-fast-confirm__hero__body u-text-align--center"
                    >
                      <span>Accommodations in</span>
                      <b
                        class="u-display--block u-font-size--big c-fast-confirm__hero__title"
                      >
                        {{ week.ResortCountry }}, {{ week.ResortCity }}
                      </b>
                    </div>
                  </div>
                  <div class="c-fast-confirm__body o-box--default--vertical">
                    <div
                      class="tc-tooltip rewards-accumulation c-fast-confirm__float-icon c-fast-confirm__float-icon--cup"
                      title=""
                      data-original-title="You will earn 0 points, available from the end of the trip on 29 Apr 2024 "
                    >
                      <!-- <i class="fa-regular fa-trophy clr--rewards-trophy"></i> -->
                    </div>
                    <div
                      id="j_id_4v:j_id_74:precioTotal"
                      class="c-price clr--dark-gray u-text-align--center precioTotal"
                    >
                      <div
                        class="c-price__primary dev-side-summary-priceShown c-fast-confirm__price u-font-size--giant u-font-size--huge@md u-font-size--giant@lg"
                      >
                        $ {{ price }} USD
                      </div>
                      <div class="c-price__bottom u-font-size--small">
                        {{ $func.parseIso("finalPrice") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="c-travel-details">
                  <div
                    class="c-travel-details__container u-display--flex@sm u-display--block@md u-gap--small"
                  >
                    <div
                      class="c-travel-details__item c-travel-detail c-travel-details__item--people o-layout__item u-1/2@sm u-1/1@md"
                    >
                      <div class="c-travel-detail__icon">
                        <i class="fa-regular fa-users"></i>
                      </div>
                      <div class="c-travel-detail__content">
                        {{ week.UnitType }} / {{ week.KitchenType }}
                      </div>
                    </div>
                    <div
                      class="c-travel-details__item c-travel-detail c-travel-details__item--dates o-layout__item u-1/2@sm u-1/1@md"
                    >
                      <div class="c-travel-detail__icon">
                        <i class="fa-regular fa-calendar-lines"></i>
                      </div>
                      <div
                        class="c-travel-detail__content trip-departure-arrival-dates"
                      >
                        {{ formatDate(week.CheckInDate) }} -
                        {{ formatDate(week.CheckOutDate) }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div
                  id="c-hidden-aside"
                  class="c-hidden-aside c-hidden-aside@md modifier pt-0"
                >
                  <div
                    class="c-sticky-header u-zindex--128 u-display--none@md o-block__item o-position--top o-position--sticky"
                  >
                    <div class="o-flexbox o-flexbox--generic-row">
                      <div
                        class="o-flexbox o-flexbox--generic-row u-align-items--center"
                      >
                        <a class="c-sticky-header__button ux-close-filters"
                          ><i class="fa-regular fa-arrow-left"></i
                        ></a>
                        <div class="u-line-height--1 u-ellipsis-inline">
                          <b> Filter and Sort</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="o-block c-hidden-aside__scroller">
                    <div
                      id="j_id_4v:j_id_tc:side-summary-aside"
                      class="side-summary-aside o-block__item"
                    >
                      <div class="tab-content mb-20">
                        <div
                          role="tabpanel"
                          class="tab-pane active"
                          id="side-travel-route"
                        >
                          <b
                            class="sidebar__title u-display--none u-display--inline-block@sm mb-10 mt-10 clr--dark-gray"
                          >
                            Your trip day to day
                          </b>
                          <div
                            class="mb-10 travel-route travel-route--without-origin false hidden-xs travel-route--hover-null"
                            data-content="Browse service by service in this column"
                            data-placement="top"
                          >
                            <div class="travel-route__travelling">
                              <div
                                class="travel-route__destination parent-clr--1"
                              >
                                <div
                                  class="travel-route__item travel-route__city travel-route__city--destination"
                                >
                                  <div class="c-route-date child__bg-clr">
                                    <b class="c-route-date__title"> 13</b
                                    ><span class="c-route-date__small">Apr</span
                                    ><span
                                      class="c-route-date__arrow child__bdr-clr--top"
                                    ></span>
                                  </div>
                                  <div class="c-route-step">
                                    <div
                                      class="c-route-step__line--top u-opacity--0"
                                    ></div>
                                    <div
                                      class="c-route-step__point c-route-step__point--lg child__clr"
                                    >
                                      <i
                                        class="fa-regular fa-map-marker-alt u-font-size--middle"
                                      ></i>
                                    </div>
                                    <div
                                      class="c-route-step__line--bottom"
                                    ></div>
                                  </div>
                                  <div class="travel-route__content">
                                    <h5 class="travel-route__city-name">
                                      Miami FL
                                    </h5>
                                  </div>
                                </div>
                                <div
                                  class="travel-route__item travel-route__hotel"
                                >
                                  <div
                                    class="c-route-date child__bg-clr--white-soft"
                                  >
                                    <i
                                      class="fa-regular fa-moon c-route-date__title"
                                    ></i
                                    ><span class="c-route-date__small">16</span>
                                  </div>
                                  <div class="c-route-step">
                                    <div class="c-route-step__line--top"></div>
                                    <div class="c-route-step__point child__clr">
                                      <i class="fa-regular fa-bed-alt"></i>
                                    </div>
                                    <div
                                      class="c-route-step__line--bottom"
                                    ></div>
                                  </div>
                                  <div
                                    class="travel-route__content travel-route__service-content"
                                  >
                                    <a
                                      href="/accommodation/accommodationCityAvail.xhtml?tripId=1&amp;availPosition=1"
                                      class="travel-route__service-details"
                                      title="Click to see all hotels in this city"
                                    >
                                      <h6
                                        class="travel-route__service__list-item travel-route__service-title"
                                      >
                                        Clarion Inn &amp; Suites Miami
                                        International Airport
                                      </h6>
                                      <div
                                        class="travel-route__service__list-item c-hotel-status c-hotel-status--sm"
                                      >
                                        <div class="c-hotel-status null mt-5">
                                          <span
                                            class="c-hotel-status__category null"
                                            ><i
                                              class="fa-solid fa-star c-hotel-status__star"
                                            ></i
                                            ><i
                                              class="fa-solid fa-star c-hotel-status__star"
                                            ></i
                                            ><i
                                              class="fa-solid fa-star c-hotel-status__star hidden"
                                            ></i
                                            ><i
                                              class="fa-solid fa-star c-hotel-status__star hidden"
                                            ></i
                                            ><i
                                              class="fa-solid fa-star c-hotel-status__star hidden"
                                            ></i
                                            ><i
                                              class="fa-solid fa-star c-hotel-status__star hidden"
                                            ></i
                                          ></span>
                                          <b
                                            class="c-hotel-status__popularity clr--rating75"
                                          >
                                            6.5
                                            <i
                                              class="fa-regular fa-thumbs-up"
                                            ></i>
                                          </b>
                                        </div>
                                      </div>
                                      <div
                                        class="travel-route__service__list-item o-group mt-5"
                                      >
                                        <i class="fa-light fa-location-dot"></i
                                        ><b class="u-font-size--small">
                                          Miami Springs</b
                                        >
                                      </div>
                                      <div
                                        class="travel-route__service__list-item travel-route__hotel-plan"
                                      >
                                        <span
                                          ><i
                                            class="fa-regular fa-utensils travel-route__detail-icon"
                                          ></i>
                                          room only</span
                                        >
                                        <span
                                          ><i
                                            class="fa-regular fa-bed travel-route__detail-icon"
                                          ></i>
                                          run of house</span
                                        >
                                      </div></a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="travel-route__insurances-list"></div>
                          </div>
                          <div id="mbox-widget"></div>
                        </div>
                        <div
                          role="tabpanel"
                          id="tab-sort"
                          class="tab-pane u-display--none@md"
                        >
                          <div
                            id="j_id_4v:j_id_tc:aside-sort"
                            class="sort-selector o-block o-block--small u-line-height--1"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="u-display--none@md">
                    <div class="o-box--small bg-clr--white">
                      <a
                        href="#"
                        class="c-button c-button--solid--default bg-clr--brand u-1/1"
                        onclick="closeHiddenAside()"
                        ><span>Apply</span></a
                      >
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div
            id="passsenger-data"
            class="passsenger-data comp-app o-layout__item u-float--left u-12/12 u-9/12@md pl-0 pl-20@md pl-40@lg"
          >
            <div
              id="searchOperatorUserSelection"
              class="searchOperatorUserSelection"
            ></div>
            <form
              id="data"
              name="data"
              method="post"
              action="/secure/passenger-data.xhtml?tripId=1"
              onkeypress="return event.keyCode !== 13;"
              enctype="application/x-www-form-urlencoded"
              data-partialsubmit="true"
            >
              <div
                id="passenger-form:validationsmessage"
                class="validationsmessage"
              ></div>
              <div
                class="tit-option opps management-fee-passengers bg-clr--lighter-gray u-text-align--center o-box--small u-border-radius--sm mb-10 u-display--none"
              >
                Management fee included
              </div>
              <div id="passenger-form:passengers" class="passengers pt-10">
                <h2 class="u-font-size--big u-font-weight--600 m-0">
                  {{ $func.parseIso("enterTravelerData") }}
                </h2>
                <div class="pt-20">
                  <div class="mb-30">
                    <div
                      id="passenger-form:distri:0:person:0:passengerData:passenger-data"
                      class="box-form passenger-data person-0-0 o-box u-box-shadow--soft u-border-radius bg-clr--white clearfix"
                    >
                      <div class="o-box--tiny--horizontal">
                        <h3
                          class="u-font-size--default u-font-weight--700 m-0 clr--brand"
                        >
                          <i class="fa-regular fa-user"></i>&nbsp;
                          {{ $func.parseIso("primaryContact") }}
                        </h3>
                      </div>
                      <div class="form-group u-1/1 u-4/12@sm u-float--left">
                        <label
                          id="passenger-form:distri:0:person:0:passengerData:j_id_2m6"
                          class="ui-outputlabel ui-widget dev-person-name-label u-font-weight--400 u-font-size--small mt-15 ui-required"
                          for="passenger-form:distri:0:person:0:passengerData:nombre"
                        >
                          {{ $func.parseIso("name")
                          }}<span class="ui-outputlabel-rfi">*</span></label
                        ><input
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1 person-name traveler-name"
                          :placeholder="$func.parseIso('name')"
                          v-model="name"
                        />
                      </div>
                      <div class="form-group u-1/1 u-4/12@sm u-float--left">
                        <label
                          id="passenger-form:distri:0:person:0:passengerData:j_id_2m6"
                          class="ui-outputlabel ui-widget dev-person-name-label u-font-weight--400 u-font-size--small mt-15 ui-required"
                          for="passenger-form:distri:0:person:0:passengerData:nombre"
                        >
                          {{ $func.parseIso("lastname")
                          }}<span class="ui-outputlabel-rfi">*</span></label
                        ><input
                          id="passenger-form:distri:0:person:0:passengerData:nombre"
                          name="passenger-form:distri:0:person:0:passengerData:nombre"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1 person-name traveler-name"
                          aria-required="true"
                          :placeholder="$func.parseIso('lastname')"
                          v-model="lastname"
                        />
                      </div>
                      <div class="form-group u-1/1 u-4/12@sm u-float--left">
                        <label
                          id="passenger-form:distri:0:person:0:passengerData:j_id_2m6"
                          class="ui-outputlabel ui-widget dev-person-name-label u-font-weight--400 u-font-size--small mt-15 ui-required"
                          for="passenger-form:distri:0:person:0:passengerData:nombre"
                        >
                          {{ $func.parseIso("email")
                          }}<span class="ui-outputlabel-rfi">*</span></label
                        ><input
                          id="passenger-form:distri:0:person:0:passengerData:nombre"
                          name="passenger-form:distri:0:person:0:passengerData:nombre"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1 person-name traveler-name"
                          aria-required="true"
                          :placeholder="$func.parseIso('email')"
                          v-model="email"
                        />
                      </div>
                      <div class="form-group u-1/1 u-4/12@sm u-float--left">
                        <label
                          id="passenger-form:distri:0:person:0:passengerData:j_id_2m6"
                          class="ui-outputlabel ui-widget dev-person-name-label u-font-weight--400 u-font-size--small mt-15 ui-required"
                          for="passenger-form:distri:0:person:0:passengerData:nombre"
                        >
                          {{ $func.parseIso("cellphone")
                          }}<span class="ui-outputlabel-rfi">*</span></label
                        ><input
                          id="passenger-form:distri:0:person:0:passengerData:nombre"
                          name="passenger-form:distri:0:person:0:passengerData:nombre"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1 person-name traveler-name"
                          aria-required="true"
                          :placeholder="$func.parseIso('cellphone')"
                          v-model="phone"
                        />
                      </div>
                      <div class="form-group u-1/1 u-4/12@sm u-float--left">
                        <label
                          id="passenger-form:distri:0:person:0:passengerData:j_id_2m6"
                          class="ui-outputlabel ui-widget dev-person-name-label u-font-weight--400 u-font-size--small mt-15 ui-required"
                          for="passenger-form:distri:0:person:0:passengerData:nombre"
                        >
                          {{ $func.parseIso("country")
                          }}<span class="ui-outputlabel-rfi">*</span></label
                        >
                        <select
                          id="j_id_54:init-compositor-all:country"
                          name="j_id_54:init-compositor-all:country"
                          size="1"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1 person-name traveler-name"
                          v-model="country"
                          style="cursor: pointer"
                          @change="checkState"
                        >
                          <option :value="null">
                            {{ $func.parseIso("countrySelected") }}
                          </option>
                          <option
                            :value="item.Country"
                            v-for="item in countries"
                          >
                            {{ item.Country }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group u-1/1 u-4/12@sm u-float--left">
                        <label
                          id="passenger-form:distri:0:person:0:passengerData:j_id_2m6"
                          class="ui-outputlabel ui-widget dev-person-name-label u-font-weight--400 u-font-size--small mt-15 ui-required"
                          for="passenger-form:distri:0:person:0:passengerData:nombre"
                        >
                          {{ $func.parseIso("state")
                          }}<span
                            class="ui-outputlabel-rfi"
                            v-if="states.length !== 0"
                            >*</span
                          ></label
                        >
                        <select
                          id="j_id_54:init-compositor-all:country"
                          name="j_id_54:init-compositor-all:country"
                          size="1"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1 person-name traveler-name"
                          v-model="state"
                          style="cursor: pointer"
                          :disabled="states.length === 0"
                        >
                          <option :value="null">
                            {{ $func.parseIso("stateSelected") }}
                          </option>
                          <option :value="item.CODIGO" v-for="item in states">
                            {{ item.NOMBRE_ESTADO }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group u-1/1 u-4/12@sm u-float--left">
                        <label
                          id="passenger-form:distri:0:person:0:passengerData:j_id_2m6"
                          class="ui-outputlabel ui-widget dev-person-name-label u-font-weight--400 u-font-size--small mt-15 ui-required"
                          for="passenger-form:distri:0:person:0:passengerData:nombre"
                        >
                          {{ $func.parseIso("city")
                          }}<span class="ui-outputlabel-rfi">*</span></label
                        ><input
                          id="passenger-form:distri:0:person:0:passengerData:nombre"
                          name="passenger-form:distri:0:person:0:passengerData:nombre"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1 person-name traveler-name"
                          aria-required="true"
                          :placeholder="$func.parseIso('city')"
                          v-model="city"
                        />
                      </div>
                      <div class="form-group u-1/1 u-4/12@sm u-float--left">
                        <label
                          id="passenger-form:distri:0:person:0:passengerData:j_id_2m6"
                          class="ui-outputlabel ui-widget dev-person-name-label u-font-weight--400 u-font-size--small mt-15 ui-required"
                          for="passenger-form:distri:0:person:0:passengerData:nombre"
                        >
                          {{ $func.parseIso("address")
                          }}<span class="ui-outputlabel-rfi">*</span></label
                        ><input
                          id="passenger-form:distri:0:person:0:passengerData:nombre"
                          name="passenger-form:distri:0:person:0:passengerData:nombre"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1 person-name traveler-name"
                          aria-required="true"
                          :placeholder="$func.parseIso('address')"
                          v-model="address"
                        />
                      </div>
                      <div class="form-group u-1/1 u-4/12@sm u-float--left">
                        <label
                          id="passenger-form:distri:0:person:0:passengerData:j_id_2m6"
                          class="ui-outputlabel ui-widget dev-person-name-label u-font-weight--400 u-font-size--small mt-15 ui-required"
                          for="passenger-form:distri:0:person:0:passengerData:nombre"
                        >
                          {{ $func.parseIso("zip")
                          }}<span class="ui-outputlabel-rfi">*</span></label
                        ><input
                          id="passenger-form:distri:0:person:0:passengerData:nombre"
                          name="passenger-form:distri:0:person:0:passengerData:nombre"
                          type="text"
                          class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1 person-name traveler-name"
                          aria-required="true"
                          :placeholder="$func.parseIso('zip')"
                          v-model="zipcode"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="passenger-form:usaCubaExtraInfoForm:usaCubaExtraInfo"
              ></div>
              <div id="passenger-form:register:passwordRegister"></div>
              <div
                id="passenger-form:passengerBaggage:j_id_2un"
                class="ui-blockui-content ui-widget ui-widget-content ui-corner-all ui-helper-hidden ui-shadow"
              ></div>
              <div id="services-details">
                <div
                  id="j_id_3m8:confirm-upgrade-selection:confirm-upgrade-selection"
                  class="c-modal confirm-upgrade-selection u-zindex--1051"
                  data-size="small"
                  role="dialog"
                  data-closeable="true"
                  tabindex="-1"
                  aria-modal="true"
                  data-active="false"
                >
                  <div class="c-modal__backmask"></div>
                </div>
                <div
                  id="remark-box"
                  class="o-block o-block--middle mt-40"
                  v-if="week && week.ImportantNotesSP"
                >
                  <div class="o-block__item">
                    <div
                      class="clr--warning o-flexbox u-align-items--center u-gap--small"
                    >
                      <h2
                        class="u-display--inline-block u-font-size--big u-font-weight--600 m-0"
                        style="color: #f7418f"
                      >
                        {{ $func.parseIso("importantInformation") }}
                      </h2>
                    </div>
                  </div>
                  <div
                    class="o-block o-block--middle o-box c-alert--warning u-box-shadow--soft u-border-radius"
                    style="color: #d25380; background-color: #f7418f"
                  >
                    <ul class="bf-feature o-block__item o-box bg-clr--white">
                      <li
                        style="text-align: justify"
                        v-html="
                          iso === 'es'
                            ? week.ImportantNotesSP
                            : week.ImportantNotesEN
                        "
                      ></li>
                      <br />
                      <p>{{ $func.parseIso("infoImportan__msg1") }}</p>
                    </ul>
                  </div>
                </div>
              </div>
            </form>
            <div
              id="passengerDataConditionsAccept"
              class="passengerDataConditionsAccept o-box u-border-radius u-box-shadow--soft bg-clr--white mt-40"
            >
              <div
                id="vecipymesmodal"
                class="c-modal vecipymesmodal"
                data-size="large"
                role="dialog"
                data-closeable="true"
                tabindex="-1"
                aria-modal="true"
                data-active="false"
              >
                <div
                  class="c-modal__backmask"
                  onclick="$('.vecipymesmodal').modal('hide')"
                ></div>
                <div class="c-modal__wrapper">
                  <div class="c-modal__container">
                    <div class="c-modal__close" style="">
                      <button
                        type="button"
                        class="c-modal__close-btn"
                        data-dismiss="modal"
                      >
                        <span
                          class="o-box--small--horizontal o-box--tiny--vertical u-font-weight--500 u-font-size--small u-border-radius--sm u-line-height--1 u-pointer-events--none"
                        >
                          Close
                          <i
                            class="fa-solid fa-caret-right u-font-size--default"
                          ></i></span
                        ><i class="u-display--block fa-regular fa-close"></i>
                      </button>
                    </div>
                    <div class="c-modal__body">
                      <div
                        class="c-modal__header u-font-weight--600 u-line-height--1 u-hide-empty"
                      >
                        Personal data information
                      </div>
                      <div class="o-block o-block--small">
                        <div class="o-block__item">
                          <h4 class="mb-5"><b>GENERAL CONDITIONS</b></h4>
                          ???web.vecipymes.general.conditions???
                        </div>
                        <div class="o-block__item">
                          <h4 class="mb-5">
                            <b>???web.vecipymes.cancel.conditions.title???</b>
                          </h4>
                          ???web.vecipymes.cancel.conditions???
                        </div>
                        <div class="o-block__item">
                          <h4 class="mb-5">
                            <b>???web.vecipymes.economic.conditions.title???</b>
                          </h4>
                          ???web.vecipymes.economic.conditions???
                        </div>
                        <div class="o-block__item">
                          <h4 class="mb-5">
                            <b>???web.vecipymes.payment.conditions.title???</b>
                          </h4>
                          ???web.vecipymes.payment.conditions???
                        </div>
                        <div class="o-block__item">
                          <h4 class="mb-5">
                            <b
                              >INTEGRITY, BUSINESS CONDUCT AND REGULATORY
                              COMPLIANCE.</b
                            >
                          </h4>
                          ???web.vecipymes.integrity.conditions???
                        </div>
                        <div class="o-block__item">
                          <h4 class="mb-5">
                            <b>???web.vecipymes.data.protection.title???</b>
                          </h4>
                          ???web.vecipymes.data.protection???
                        </div>
                        <div class="o-block__item">
                          <h4 class="mb-5">
                            <b
                              >???web.vecipymes.insurance.conditions.title???</b
                            >
                          </h4>
                          ???web.vecipymes.insurance.conditions???
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h5>
                <i class="fa-regular fa-info-circle mr-5"></i
                ><b>{{ $func.parseIso("termsAndConditions") }}</b>
                <span class="clr--warning" style="color: #f7418f"
                  ><em> {{ $func.parseIso("mandatory") }}</em></span
                >
              </h5>
              <div class="mb-5">
                <div
                  id="general-conditions:gdprconsentmodal"
                  class="c-modal gdprconsentmodal"
                  data-size="medium"
                  role="dialog"
                  data-closeable="true"
                  tabindex="-1"
                  aria-modal="true"
                  data-active="false"
                >
                  <div
                    class="c-modal__backmask"
                    onclick="$('.gdprconsentmodal').modal('hide')"
                  ></div>
                  <div class="c-modal__wrapper">
                    <div class="c-modal__container">
                      <div class="c-modal__close" style="">
                        <button
                          type="button"
                          class="c-modal__close-btn"
                          data-dismiss="modal"
                        >
                          <span
                            class="o-box--small--horizontal o-box--tiny--vertical u-font-weight--500 u-font-size--small u-border-radius--sm u-line-height--1 u-pointer-events--none"
                          >
                            Close
                            <i
                              class="fa-solid fa-caret-right u-font-size--default"
                            ></i></span
                          ><i class="u-display--block fa-regular fa-close"></i>
                        </button>
                      </div>
                      <div class="c-modal__body">
                        <div
                          class="c-modal__header u-font-weight--600 u-line-height--1 u-hide-empty"
                        >
                          Personal data information
                        </div>
                        <div class="u-line-break--anywhere">-</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="o-flexbox u-gap--small">
                  <input
                    id="general-conditions:generaltermsConsent"
                    type="checkbox"
                    name="general-conditions:generaltermsConsent"
                    :value="true"
                    class="condiciones must-check c-checkbox"
                    data-message="You must accept the remarks and cancellation costs before you continue with the booking process"
                    autocomplete="off"
                    style="cursor: pointer"
                    v-model="terms"
                  />
                  <div>
                    <label
                      class="u-display--inline u-font-weight--400"
                      for="general-conditions:generaltermsConsent"
                    >
                      {{ $func.parseIso("msgTermsAndConditions") }}
                    </label>
                    <!-- <label
                      class="u-display--inline u-font-weight--400"
                      for="general-conditions:generaltermsConsent"
                      >conditions of use, the booking remarks and cancellation
                      costs.</label
                    > -->
                  </div>
                </div>
              </div>
              <div
                id="general-conditions:modal-conditions-error"
                class="c-modal modal-conditions-error c-modal-alert u-text-align--center"
                data-size="small"
                role="dialog"
                data-closeable="true"
                tabindex="-1"
                aria-modal="true"
                data-active="false"
              >
                <div
                  class="c-modal__backmask"
                  onclick="$('.modal-conditions-error').modal('hide')"
                ></div>
                <div class="c-modal__wrapper">
                  <div class="c-modal__container">
                    <div class="c-modal__close" style="">
                      <button
                        type="button"
                        class="c-modal__close-btn"
                        data-dismiss="modal"
                      >
                        <span
                          class="o-box--small--horizontal o-box--tiny--vertical u-font-weight--500 u-font-size--small u-border-radius--sm u-line-height--1 u-pointer-events--none"
                        >
                          Close
                          <i
                            class="fa-solid fa-caret-right u-font-size--default"
                          ></i></span
                        ><i class="u-display--block fa-regular fa-close"></i>
                      </button>
                    </div>
                    <div class="c-modal__body">
                      <div
                        class="c-modal__header u-font-weight--600 u-line-height--1 u-hide-empty"
                      ></div>
                      <div class="o-block o-block--small">
                        <b
                          class="o-block__item u-font-size--big u-text-transform--capitalize--first-letter"
                          >Warning</b
                        >
                        <div class="o-block__item">
                          You must accept the remarks and cancellation costs
                          before you continue with the booking process
                        </div>
                        <div class="o-block__item">
                          <a
                            type="button"
                            class="c-button bg-clr--brand"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >
                            OK
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div
              id="form"
              name="form"
              method="post"
              action="/secure/passenger-data.xhtml?tripId=1"
              enctype="application/x-www-form-urlencoded"
              data-partialsubmit="true"
            >
              <div
                class="register-button o-flexbox o-flexbox--generic-row u-gap--small mt-20"
              >
                <div class="u-flex--11 u-flex--none@sm">
                  <a
                    @click="goBack"
                    class="register-button--back c-button c-button--lg@sm bdr-clr--brand mb-10"
                  >
                    {{ $func.parseIso("back") }}
                  </a>
                </div>
                <div class="u-flex--11 u-flex--none@sm">
                  <button
                    id="form:j_id_4lh"
                    name="form:j_id_4lh"
                    class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only c-button--continue c-button c-button--lg@sm bg-clr--brand"
                    @click="hold"
                  >
                    <span class="ui-button-text ui-c">{{
                      $func.parseIso("next")
                    }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Alert :show="alert.show" :title="alert.title" :closeAlert="alert.close" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Alert from "../components/Alert.vue";
import { getSesion } from "../utils/sesions";

export default {
  data() {
    return {
      countries: [],
      states: [],
      name: null,
      lastname: null,
      email: null,
      phone: null,
      address: null,
      city: null,
      state: null,
      zipcode: null,
      country: null,
      weekId: null,
      week: null,
      price: null,
      iso: null,
      terms: false,
      numberRef: null,
      textLoading: null,
      intervalId: null,
      loading: false,
      alert: {
        show: false,
        title: null,
        description: null,
        close: null,
      },
    };
  },
  components: {
    Header,
    Alert,
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
      this.$forceUpdate();
    });

    await this.checkParams();
    await this.listWeek();
    await this.listCountries();
    await this.$bus.emit("footer-show", true);
  },
  methods: {
    async listCountries() {
      try {
        const { data } = await this.$axios.get("/week/countries-all");
        this.countries = data.countries;
        // this.countries = this.countries.sort((a, b) =>
        //   a.Country === "USA" ||
        //   a.Country === "Mexico" ||
        //   a.Country === "Dominican Republic"
        //     ? -1
        //     : 0
        // );
        // console.log(this.countries);
      } catch (error) {
        console.log(error);
      }
    },
    async checkState() {
      if (this.country) {
        const c = this.countries.find((c) => c.Country === this.country);
        if (c && c.TIPO > 0) {
          await this.listState();
        } else {
          this.state = null;
          this.states = [];
        }
      } else {
        this.state = null;
        this.states = [];
      }
    },
    async listState() {
      try {
        const { data } = await this.$axios.get(
          `/week/states?country=${this.country}`
        );
        this.states = data.states;
        // console.log(this.countries);
      } catch (error) {
        console.log(error);
      }
    },
    async checkParams() {
      const { weekId } = this.$route.query;
      this.weekId = weekId || null;
    },
    async listWeek() {
      if (this.weekId) {
        try {
          const { data } = await this.$axios.get(`/week/only/${this.weekId}`);
          this.week = data.week;
          this.price = data.week.Cost;
          // console.log(this.countries);
        } catch (error) {
          console.log(error);
        }
      } else this.$router.push("/");
    },
    goBack() {
      this.$router.go(-1);
    },
    formatDate(date) {
      return this.$moment(date).format("ll");
    },
    checkClient() {
      let pass = true;
      let msg = "";

      const rgxOnlyTextAndSpace = /^[a-zA-Z \u00C0-\u024F\u1E00-\u1EFF]*$/;
      const rgxEmail = /^[a-z0-9\._+\-]+@([a-z0-9\-]+\.){1,}([a-z]{2,16})*$/;

      if (
        !this.name ||
        !this.lastname ||
        !this.email ||
        !this.phone ||
        !this.country ||
        !this.city ||
        !this.address ||
        !this.zipcode ||
        !this.terms
      ) {
        pass = false;
        msg = this.$func.parseIso("allFieldsRequired");
      }

      if (!this.terms) {
        pass = false;
        msg = this.$func.parseIso("acceptTerms");
      }

      if (this.country) {
        const country = this.countries.find((c) => c.Country === this.country);
        if (country && country.TIPO > 0 && !this.state) {
          pass = false;
          msg = this.$func.parseIso("fieldStateRequired");
          msg = msg.replace("{{country}}", this.country);
        }
      }
      if (this.name && !rgxOnlyTextAndSpace.test(this.name)) {
        pass = false;
        msg = this.$func.parseIso("fieldOnlyLettersAndSpaces");
        msg = msg.replace("{{field}}", this.$func.parseIso("name"));
      }
      if (this.lastname && !rgxOnlyTextAndSpace.test(this.lastname)) {
        pass = false;
        msg = this.$func.parseIso("fieldOnlyLettersAndSpaces");
        msg = msg.replace("{{field}}", this.$func.parseIso("lastname"));
      }
      if (this.email && !rgxEmail.test(this.email)) {
        pass = false;
        msg = this.$func.parseIso("emailInvalid");
      }

      return { pass, msg };
    },
    async hold() {
      try {
        const { pass, msg } = this.checkClient();
        if (pass && !this.loading) {
          // this.intervalId = setInterval(
          //   (_this) => {
          //     // console.log("_this.textLoading", _this.textLoading);
          //     _this.textLoading += ".";
          //     if (_this.textLoading.split("").length >= 2) {
          //       clearInterval(_this.intervalId);
          //     }
          //   },
          //   800,
          //   this
          // );
          this.loading = true;
          const sesionTC = getSesion();
          const payload = {
            client: {
              name: this.name,
              lastname: this.lastname,
              email: this.email,
              phone: this.phone,
              address: this.address,
              city: this.city,
              state: this.state || "",
              zipcode: this.zipcode,
              country: this.country,
              iso: this.iso || "es",
              loginId: sesionTC?.LoginID || null,
            },
            inventoryId: this.week.InventoryID,
            createdBy: sesionTC?.LoginID || "weeks",
          };
          console.log("payload", payload);
          console.log("terms", this.terms);
          const { data } = await this.$axios.post(`/week/hold`, {
            ...payload,
          });
          // this.textLoading += ".";
          this.numberRef = data.numberRef;
          this.$router.push(`/resort-payment?numberRef=${this.numberRef}`);
        } else {
          this.alert.title = msg;
          this.alert.close = this.closeAlert;
          this.openAlert();
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    openAlert() {
      this.alert.show = true;
    },
    closeAlert() {
      this.alert.show = false;
    },
  },
};
</script>
