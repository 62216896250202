<template>
  <div
    id="mainForm:datascrollHorizontal_paginator_bottom"
    class="ui-paginator ui-paginator-bottom ui-widget-header ui-corner-bottom"
    role="navigation"
    aria-label="Pagination"
  >
    <!-- <a
      class="ui-paginator-first ui-state-default ui-corner-all ui-state-disabled"
      aria-label="First Page"
      tabindex="-1"
      ><span class="ui-icon ui-icon-seek-first">F</span></a
    > -->
    <a
      @click="returnPage"
      class="ui-paginator-prev ui-state-default ui-corner-all"
      aria-label="Previous Page"
      tabindex="-1"
      ><span class="ui-icon ui-icon-seek-prev">P</span></a
    ><span class="ui-paginator-pages">
      <a
        v-for="bp in beforePages"
        class="ui-paginator-page ui-state-default ui-corner-all"
        @click="setPage(bp)"
        aria-label="Page 0"
        tabindex="0"
        >{{ bp }}</a
      >
      <a
        class="ui-paginator-page ui-state-default ui-state-active ui-corner-all"
        tabindex="0"
        aria-label="Page 1"
        aria-current="page"
        >{{ actualPage }}</a
      >
      <a
        v-for="ap in afterPages"
        class="ui-paginator-page ui-state-default ui-corner-all"
        @click="setPage(ap)"
        aria-label="Page 2"
        tabindex="0"
        >{{ ap }}</a
      >
    </span>
    <a
      @click="nextPage"
      class="ui-paginator-next ui-state-default ui-corner-all"
      aria-label="Next Page"
      tabindex="0"
      ><span class="ui-icon ui-icon-seek-next">N</span></a
    >
    <!-- <a
      class="ui-paginator-last ui-state-default ui-corner-all"
      aria-label="Last Page"
      tabindex="0"
      ><span class="ui-icon ui-icon-seek-end">E</span></a
    > -->
    <div class="clr--brand paginator-num-hotels pt-5">{{ total }} total</div>
  </div>
</template>

<script>
import { country } from "@/utils/i18n_data";
import { setSearchWeekObject, getSearchWeekObject } from "../../utils/sesions";

export default {
  data() {
    return {
      beforePages: [],
      afterPages: [],
    };
  },
  props: [
    "actualPage",
    "totalPage",
    "total",
    "limit",
    "country",
    "city",
    "checkIn",
    "checkOut",
    "token",
  ],
  created() {
    // this.getPages();

    this.$bus.on("refresh-page", (totalPage) => {
      // this.totalPage = totalPage;
      this.getPages(totalPage);
      this.$forceUpdate();
    });
  },
  methods: {
    async nextPage() {
      // console.log(this.country, this.city, this.checkOut, this.checkIn);

      const filters = getSearchWeekObject();

      if (parseInt(this.actualPage) < this.totalPage) {
        await setSearchWeekObject({
          ...filters,
          country: this.country,
          city: this.city,
          checkIn: this.checkIn,
          page: parseInt(this.actualPage) + 1,
          limit: this.limit,
          checkOut: this.checkOut,
        });
        this.$bus.emit("change-page");
      }

      // this.$router.push(
      //   `/resortlist?country=${this.country}&city=${this.city}&checkIn=${
      //     this.checkIn
      //   }
      //   &checkOut=${this.checkOut}&page=${
      //     parseInt(this.actualPage) + 1
      //   }&limit=${this.limit}&token=${this.token}`
      // );
      // this.$router.go(0);
    },
    async returnPage() {
      // console.log(this.country, this.city, this.checkOut, this.checkIn);

      const filters = getSearchWeekObject();

      if (parseInt(this.actualPage) > 1) {
        await setSearchWeekObject({
          ...filters,
          country: this.country,
          city: this.city,
          checkIn: this.checkIn,
          page: parseInt(this.actualPage) - 1,
          limit: this.limit,
          checkOut: this.checkOut,
        });
        this.$bus.emit("change-page");
      }

      // this.$router.push(
      //   `/resortlist?country=${this.country}&city=${this.city}&checkIn=${
      //     this.checkIn
      //   }
      //   &checkOut=${this.checkOut}&page=${
      //     parseInt(this.actualPage) - 1
      //   }&limit=${this.limit}&token=${this.token}`
      // );
      // this.$router.go(0);
    },
    getPages(totalPage) {
      // console.log("this.actualPage", this.actualPage);
      // console.log("totalPage", totalPage);

      this.beforePages = [3, 2, 1]
        .map((bp) => parseInt(this.actualPage) - bp)
        .filter((bp) => bp > 0);

      // console.log("this.beforePages", this.beforePages);

      this.afterPages = [1, 2, 3]
        .map((bp) => parseInt(this.actualPage) + bp)
        .filter((bp) => bp <= parseInt(totalPage));

      // console.log("this.afterPages", this.afterPages);
    },
    async setPage(page) {
      const filters = getSearchWeekObject();
      await setSearchWeekObject({
        ...filters,
        country: this.country,
        city: this.city,
        checkIn: this.checkIn,
        page,
        limit: this.limit,
        checkOut: this.checkOut,
      });
      this.$bus.emit("change-page");
    },
  },
};
</script>
