<template>
  <div id="map" class="map" />
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyB5RzDiCUMr6oOQAJ4SPTmxlCgXh51AL8U",
  version: "weekly",
  libraries: ["places"],
  mapOptions: {
    center: { lat: -34.397, lng: 150.644 },
    zoom: 8,
  },
});

export default {
  data() {
    return {};
  },
  props: ["lat", "lon", "resortName"],
  async mounted() {
    await loader
      .load()
      .then((google) => {
        console.log("latlon", this.lat, this.lon);
        const myLatlng = new google.maps.LatLng(this.lat, this.lon);

        console.log("myLatlng", myLatlng);

        const map = new google.maps.Map(document.getElementById("map"), {
          center: myLatlng,
          zoom: 8,
        });
        new google.maps.Marker({
          position: myLatlng,
          map,
          title: this.resortName || ''
        });
      })
      .catch((e) => {
        console.log("error map", e);
      });
  },
};
</script>

<style>
html,
body,
#map {
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
