<template>
  <div id="body">
    <div id="checkout"></div>
  </div>
</template>

<script>
const stripe = Stripe(process.env.VUE_APP_KEY_STRIPE);

export default {
  data() {
    return {
      elements: null,
      loading: false,
      errorMessage: null,
      checkout: null,
    };
  },
  props: ["clientSecret", "price", "numberRef", "token"],
  async mounted() {
    await this.initialize();
  },
  destroyed() {
    this.checkout.destroy();
    console.log("destruyendo");
  },
  unmounted() {
    this.checkout.unmount();
    console.log("desmontando");
  },
  methods: {
    async pay() {
      try {
        this.loading = true;
        this.errorMessage = null;
        const { error } = await stripe.confirmPayment({
          elements: this.elements,
          // redirect: "if_required",
          confirmParams: {
            return_url: `http://locahost:8081/pay-ok`,
          },
        });
        this.loading = false;
        console.log("error stripe", error);
        if (error) {
          this.errorMessage = error?.message || "An unexpected error occurred.";
        } else {
          this.errorMessage = this.$func.parseIso("successfulPayment");
          this.confirm();
        }
        //   this.$modal.hide("pay");
      } catch (error) {
        console.log(error);
        this.errorMessage = "An unexpected error occurred.";
      }
    },

    async confirm() {
      try {
        await this.$axios.get(
          `/payment/confirm/payment-intent?numberRef=${this.numberRef}`
        );
        this.$bus.emit("confirm-pay");
        this.$modal.hide("pay");
      } catch (error) {
        console.log(error);
      }
    },

    async initialize() {
      // const fetchClientSecret = async () => {
      //   const { data } = await this.$axios.get(
      //     `/payment/create/payment-checkout?numberRef=${this.numberRef}`
      //   );
      //   return data.clientSecret;
      // };

      const fetchClientSecret = async () => {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/payment/create/payment-checkout?numberRef=${this.numberRef}`,
          {
            method: "GET",
          }
        );
        const { clientSecret } = await response.json();
        console.log("clientSecret", clientSecret);
        return clientSecret;
      };

      // const fetchClientSecret = async () => {
      //   console.log("this.clientSecret", this.clientSecret);
      //   return await this.clientSecret;
      // };

      const checkout = await stripe.initEmbeddedCheckout({
        fetchClientSecret,
      });

      this.checkout = checkout;

      // Mount Checkout
      checkout.mount("#checkout");
    },
  },
};
</script>

<style scoped>
/* Variables */
* {
  box-sizing: border-box;
}

#body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  /* height: 100vh;
  width: 100vw; */
}

#payment-form {
  position: absolute;
  top: 20%;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
</style>
