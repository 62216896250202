<template>
  <div>
    <Header />
    <modal
      name="pay-checkout"
      :width="400"
      height="auto"
      :adaptive="false"
      :scrollable="true"
      @before-close="bCloseModal"
    >
      <ModalStripeCheckout :numberRef="numberRef" :price="price" />
    </modal>
    <div class="main">
      <div
        class="container o-container--large booking-process reserva"
        id="RESERVA"
        role="main"
      >
        <nav class="navbar navegacion2" role="navigation" id="navegacion2">
          <div
            id="j_id_2r:breadcrumbs-availability"
            class="wrapperNavbar breadcrumbs-availability"
          ></div>
          <div id="breadcrumb-bottom"></div>
        </nav>
        <div class="o-layout">
          <div
            id="col-left"
            class="o-layout__item u-float--left u-12/12 u-3/12@md"
          >
            <div class="col-left--box">
              <div
                id="comp-summary"
                class="ongoing hidden-print comp-summary only-hotel"
              >
                <nav
                  class="float-breadcrumb navbar o-position o-position--above o-position--fixed o-position--top u-1/1"
                  role="navigation"
                  id="navegacion"
                  style="display: none; z-index: 1001"
                >
                  <header class="nav-brochure">
                    <div
                      id="j_id_4v:breadcrumbs-availability"
                      class="wrapperNavbar breadcrumbs-availability"
                    ></div>
                  </header>
                </nav>
                <div class="c-fast-confirm mb-10 true">
                  <div
                    class="c-fast-confirm__heading o-layout o-layout--stretch o-layout--stretch--middle"
                  >
                    <b class="u-font-weight--600">
                      {{ $func.parseIso("resortSummary") }}</b
                    >
                  </div>
                  <div
                    class="c-fast-confirm__hero c-fast-confirm__hero--destination"
                  >
                    <div class="c-fast-confirm__hero__wrapper-img">
                      <img
                        data-src="https://cdn5.travelconline.com/unsafe/fit-in/0x200/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FDydSA3d4NdjY-2CNsl1uTcl4DDyY.jpeg"
                        src="https://cdn5.travelconline.com/unsafe/fit-in/0x200/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FDydSA3d4NdjY-2CNsl1uTcl4DDyY.jpeg"
                        data-index=""
                        data-error-src="/javax.faces.resource/images/no-photo-XS.jpg"
                        onerror="this.setAttribute('data-original-url', this.src); var errorUrl = this.getAttribute('data-error-src'); if(this.src !== errorUrl) {this.src = errorUrl};"
                        loading="lazy"
                        alt="Miami FL"
                        width=""
                        height=""
                        onclick=""
                        style=""
                        class="lazy-img c-fast-confirm__hero__img loaded"
                        data-ll-status="loaded"
                      />
                    </div>
                    <div
                      class="c-fast-confirm__hero__body u-text-align--center"
                    >
                      <span>Accommodations in</span>
                      <b
                        class="u-display--block u-font-size--big c-fast-confirm__hero__title"
                      >
                        {{ week.ResortCountry }}, {{ week.ResortCity }}
                      </b>
                    </div>
                  </div>
                  <div class="c-fast-confirm__body o-box--default--vertical">
                    <div
                      class="tc-tooltip rewards-accumulation c-fast-confirm__float-icon c-fast-confirm__float-icon--cup"
                      title=""
                      data-original-title="You will earn 0 points, available from the end of the trip on 29 Apr 2024 "
                    >
                      <!-- <i class="fa-regular fa-trophy clr--rewards-trophy"></i> -->
                    </div>
                    <div
                      id="j_id_4v:j_id_74:precioTotal"
                      class="c-price clr--dark-gray u-text-align--center precioTotal"
                    >
                      <div
                        class="c-price__primary dev-side-summary-priceShown c-fast-confirm__price u-font-size--giant u-font-size--huge@md u-font-size--giant@lg"
                      >
                        $ {{ price }} USD
                      </div>
                      <div class="c-price__bottom u-font-size--small">
                        {{ $func.parseIso("finalPrice") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="c-travel-details">
                  <div
                    class="c-travel-details__container u-display--flex@sm u-display--block@md u-gap--small"
                  >
                    <div
                      class="c-travel-details__item c-travel-detail c-travel-details__item--people o-layout__item u-1/2@sm u-1/1@md"
                    >
                      <div class="c-travel-detail__icon">
                        <i class="fa-regular fa-users"></i>
                      </div>
                      <div class="c-travel-detail__content">
                        {{ week.UnitType }} / {{ week.KitchenType }}
                      </div>
                    </div>
                    <div
                      class="c-travel-details__item c-travel-detail c-travel-details__item--dates o-layout__item u-1/2@sm u-1/1@md"
                    >
                      <div class="c-travel-detail__icon">
                        <i class="fa-regular fa-calendar-lines"></i>
                      </div>
                      <div
                        class="c-travel-detail__content trip-departure-arrival-dates"
                      >
                        {{ formatDate(week.CheckIn) }} -
                        {{ formatDate(week.CheckOut) }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div
                  id="c-hidden-aside"
                  class="c-hidden-aside c-hidden-aside@md modifier pt-0"
                >
                  <div
                    class="c-sticky-header u-zindex--128 u-display--none@md o-block__item o-position--top o-position--sticky"
                  >
                    <div class="o-flexbox o-flexbox--generic-row">
                      <div
                        class="o-flexbox o-flexbox--generic-row u-align-items--center"
                      >
                        <a class="c-sticky-header__button ux-close-filters"
                          ><i class="fa-regular fa-arrow-left"></i
                        ></a>
                        <div class="u-line-height--1 u-ellipsis-inline">
                          <b> Filter and Sort</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="o-block c-hidden-aside__scroller">
                    <div
                      id="j_id_4v:j_id_tc:side-summary-aside"
                      class="side-summary-aside o-block__item"
                    >
                      <div class="tab-content mb-20">
                        <div
                          role="tabpanel"
                          class="tab-pane active"
                          id="side-travel-route"
                        >
                          <b
                            class="sidebar__title u-display--none u-display--inline-block@sm mb-10 mt-10 clr--dark-gray"
                          >
                            Your trip day to day
                          </b>
                          <div
                            class="mb-10 travel-route travel-route--without-origin false hidden-xs travel-route--hover-null"
                            data-content="Browse service by service in this column"
                            data-placement="top"
                          >
                            <div class="travel-route__travelling">
                              <div
                                class="travel-route__destination parent-clr--1"
                              >
                                <div
                                  class="travel-route__item travel-route__city travel-route__city--destination"
                                >
                                  <div class="c-route-date child__bg-clr">
                                    <b class="c-route-date__title"> 13</b
                                    ><span class="c-route-date__small">Apr</span
                                    ><span
                                      class="c-route-date__arrow child__bdr-clr--top"
                                    ></span>
                                  </div>
                                  <div class="c-route-step">
                                    <div
                                      class="c-route-step__line--top u-opacity--0"
                                    ></div>
                                    <div
                                      class="c-route-step__point c-route-step__point--lg child__clr"
                                    >
                                      <i
                                        class="fa-regular fa-map-marker-alt u-font-size--middle"
                                      ></i>
                                    </div>
                                    <div
                                      class="c-route-step__line--bottom"
                                    ></div>
                                  </div>
                                  <div class="travel-route__content">
                                    <h5 class="travel-route__city-name">
                                      Miami FL
                                    </h5>
                                  </div>
                                </div>
                                <div
                                  class="travel-route__item travel-route__hotel"
                                >
                                  <div
                                    class="c-route-date child__bg-clr--white-soft"
                                  >
                                    <i
                                      class="fa-regular fa-moon c-route-date__title"
                                    ></i
                                    ><span class="c-route-date__small">16</span>
                                  </div>
                                  <div class="c-route-step">
                                    <div class="c-route-step__line--top"></div>
                                    <div class="c-route-step__point child__clr">
                                      <i class="fa-regular fa-bed-alt"></i>
                                    </div>
                                    <div
                                      class="c-route-step__line--bottom"
                                    ></div>
                                  </div>
                                  <div
                                    class="travel-route__content travel-route__service-content"
                                  >
                                    <a
                                      href="/accommodation/accommodationCityAvail.xhtml?tripId=1&amp;availPosition=1"
                                      class="travel-route__service-details"
                                      title="Click to see all hotels in this city"
                                    >
                                      <h6
                                        class="travel-route__service__list-item travel-route__service-title"
                                      >
                                        Clarion Inn &amp; Suites Miami
                                        International Airport
                                      </h6>
                                      <div
                                        class="travel-route__service__list-item c-hotel-status c-hotel-status--sm"
                                      >
                                        <div class="c-hotel-status null mt-5">
                                          <span
                                            class="c-hotel-status__category null"
                                            ><i
                                              class="fa-solid fa-star c-hotel-status__star"
                                            ></i
                                            ><i
                                              class="fa-solid fa-star c-hotel-status__star"
                                            ></i
                                            ><i
                                              class="fa-solid fa-star c-hotel-status__star hidden"
                                            ></i
                                            ><i
                                              class="fa-solid fa-star c-hotel-status__star hidden"
                                            ></i
                                            ><i
                                              class="fa-solid fa-star c-hotel-status__star hidden"
                                            ></i
                                            ><i
                                              class="fa-solid fa-star c-hotel-status__star hidden"
                                            ></i
                                          ></span>
                                          <b
                                            class="c-hotel-status__popularity clr--rating75"
                                          >
                                            6.5
                                            <i
                                              class="fa-regular fa-thumbs-up"
                                            ></i>
                                          </b>
                                        </div>
                                      </div>
                                      <div
                                        class="travel-route__service__list-item o-group mt-5"
                                      >
                                        <i class="fa-light fa-location-dot"></i
                                        ><b class="u-font-size--small">
                                          Miami Springs</b
                                        >
                                      </div>
                                      <div
                                        class="travel-route__service__list-item travel-route__hotel-plan"
                                      >
                                        <span
                                          ><i
                                            class="fa-regular fa-utensils travel-route__detail-icon"
                                          ></i>
                                          room only</span
                                        >
                                        <span
                                          ><i
                                            class="fa-regular fa-bed travel-route__detail-icon"
                                          ></i>
                                          run of house</span
                                        >
                                      </div></a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="travel-route__insurances-list"></div>
                          </div>
                          <div id="mbox-widget"></div>
                        </div>
                        <div
                          role="tabpanel"
                          id="tab-sort"
                          class="tab-pane u-display--none@md"
                        >
                          <div
                            id="j_id_4v:j_id_tc:aside-sort"
                            class="sort-selector o-block o-block--small u-line-height--1"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="u-display--none@md">
                    <div class="o-box--small bg-clr--white">
                      <a
                        href="#"
                        class="c-button c-button--solid--default bg-clr--brand u-1/1"
                        onclick="closeHiddenAside()"
                        ><span>Apply</span></a
                      >
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div
            class="comp-app o-layout__item u-float--left u-12/12 u-9/12@md pl-0 pl-20@md pl-40@lg"
          >
            <div class="payment-title mb-10">
              <div class="c-alert__container null">
                <div
                  id="j_id_2i8"
                  class="c-alert c-alert--warning time-warning u-font-weight--400 u-1/1"
                >
                  <strong class="pr-5">{{ $func.parseIso("warning") }}</strong>
                  {{ $func.parseIso("msgConfirmPayment") }}
                </div>
              </div>
            </div>
            <div
              id="j_id_2jk:box-rewards-redeemption-system-panel"
              class="trip-discount"
            ></div>
            <div
              id="j_id_2jk:paymentPanel"
              class="o-block o-block--big box-methods mt-20 mb-20"
            >
              <div class="o-block__item payment-options">
                <h2 class="u-font-size--middle u-font-weight--600 m-0 pb-15">
                  {{ $func.parseIso("paymentOptions") }}
                </h2>
                <span id="j_id_2jk:paymentType" class="ui-helper-hidden"
                  ><div class="ui-helper-hidden-accessible">
                    <input
                      id="j_id_2jk:paymentType:0"
                      name="j_id_2jk:paymentType"
                      type="radio"
                      data-p-required="true"
                      data-p-hl="oneradio"
                      data-p-grouped="true"
                    />
                  </div>
                  <div class="ui-helper-hidden-accessible">
                    <input
                      id="j_id_2jk:paymentType:1"
                      name="j_id_2jk:paymentType"
                      type="radio"
                      checked="checked"
                      data-p-required="true"
                      data-p-hl="oneradio"
                      data-p-grouped="true"
                    /></div
                ></span>
                <div
                  class="o-layout o-layout--stretch o-layout--gutter-default"
                >
                  <div class="o-layout__item u-1/1 u-6/12@md pb-20">
                    <div
                      class="o-box u-1/1 u-box-shadow--soft u-border-radius bg-clr--white"
                    >
                      <div
                        class="o-flexbox u-gap--small u-align-items--center u-height--100"
                      >
                        <div>
                          <div
                            id="j_id_2jk:r1"
                            class="ui-radiobutton ui-widget"
                          >
                            <div class="ui-helper-hidden-accessible">
                              <input
                                id="j_id_2jk:paymentType:1_clone"
                                name="j_id_2jk:paymentType_clone"
                                type="radio"
                                class="ui-radio-clone"
                                data-itemindex="1"
                                aria-required="true"
                                value="RETAIL_PRICE"
                              />
                            </div>
                            <div
                              class="ui-radiobutton-box ui-widget ui-corner-all ui-state-default ui-state-active"
                            >
                              <span
                                class="ui-radiobutton-icon ui-icon ui-c ui-icon-bullet"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <label
                          id="j_id_2jk:j_id_2lv"
                          class="ui-outputlabel ui-widget label-payment-retail m-0 u-font-weight--400 u-line-height--125"
                          title="retail-price"
                          for="j_id_2jk:paymentType:1_clone"
                        >
                          <div>
                            <b class="u-display--block pb-5">{{
                              $func.parseIso("finalPrice")
                            }}</b
                            ><span
                              data-amount="1659.94"
                              class="payment-retail-amount"
                            >
                              $ {{ price }} USD
                            </span>
                            <!-- <b
                              class="u-display--block clr--dark-gray u-font-size--small mt-5"
                              >Retail price</b
                            > -->
                          </div></label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="o-block__item bm-systems">
                <div id="j_id_2jk:paymentSystems" class="panel-payment-systems">
                  <div
                    class="o-flexbox u-gap--small u-align-items--end u-flex-wrap--wrap pb-10"
                  >
                    <h2 class="u-font-size--middle u-font-weight--600 m-0">
                      {{ $func.parseIso("paymentSystem") }}
                    </h2>
                    <span
                      ><img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png"
                        alt="Comodo-ssl"
                        width="40"
                      />
                    </span>
                  </div>
                  <span id="j_id_2jk:paymentSystem" class="ui-helper-hidden"
                    ><div class="ui-helper-hidden-accessible">
                      <input
                        id="j_id_2jk:paymentSystem:0"
                        name="j_id_2jk:paymentSystem"
                        type="radio"
                        value="PaymentConfiguration [id=3391, description=Stripe Vacancy Rewards, active=true, production=true, system=STRIPE_CHECKOUT, target=B2B_AND_B2C]"
                        checked="checked"
                        data-p-rmsg="Payment System is mandatory"
                        data-p-required="true"
                        data-p-hl="oneradio"
                        data-p-grouped="true"
                      />
                    </div>
                    <div class="ui-helper-hidden-accessible">
                      <input
                        id="j_id_2jk:paymentSystem:1"
                        name="j_id_2jk:paymentSystem"
                        type="radio"
                        value="PaymentConfiguration [id=3382, description=Deposit payment, active=true, production=true, system=DEPOSIT, target=B2B]"
                        data-p-rmsg="Payment System is mandatory"
                        data-p-required="true"
                        data-p-hl="oneradio"
                        data-p-grouped="true"
                      /></div
                  ></span>
                  <div
                    class="o-layout o-layout--stretch o-layout--gutter-default u-justify-content--start"
                  >
                    <h1></h1>
                    <div
                      class="tc-tooltip-dev o-layout__item u-1/1 u-1/2@xs u-1/3@md u-1/4@lg mb-20"
                      data-original-title=""
                      title=""
                    >
                      <div
                        class="c-payment-method u-box-shadow--soft u-border-radius u-position--relative u-1/1 bg-clr--white"
                        title="Stripe Checkout"
                      >
                        <div class="c-payment-method__radio o-box--small pb-0">
                          <div
                            id="j_id_2jk:j_id_2wk:0:radio"
                            class="ui-radiobutton ui-widget radio-payment-STRIPE_CHECKOUT"
                          >
                            <div class="ui-helper-hidden-accessible">
                              <input
                                id="j_id_2jk:paymentSystem:0_clone"
                                name="j_id_2jk:paymentSystem_clone"
                                type="radio"
                                class="ui-radio-clone"
                                data-itemindex="0"
                                aria-required="true"
                                value="PaymentConfiguration [id=3391, description=Stripe Vacancy Rewards, active=true, production=true, system=STRIPE_CHECKOUT, target=B2B_AND_B2C]"
                              />
                            </div>
                            <div
                              class="ui-radiobutton-box ui-widget ui-corner-all ui-state-default ui-state-active"
                            >
                              <span
                                class="ui-radiobutton-icon ui-icon ui-c ui-icon-bullet"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <label
                          class="c-payment-method__info o-box o-box--big--horizontal pt-0 u-text-align--center u-1/1 mb-0"
                          for="j_id_2jk:j_id_2wk:0:radio"
                        >
                          <span
                            class="payment-images o-flexbox u-justify-content--center u-align-items--center u-flex-wrap--wrap u-height--100 u-1/1 mb-0 payment-STRIPE_CHECKOUT payment-VE"
                          >
                            <img
                              data-src="https://booking.vacancyrewards.com/img/branding/stripe/stripe_payments.jpg"
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUH963bPf-Wn-eI93_KdTwPklZcatcQOqZ9asHISmHAQ&s"
                              data-index=""
                              data-error-src="https://booking.vacancyrewards.com/javax.faces.resource/images/no-photo-XS.jpg"
                              onerror="this.setAttribute('data-original-url', this.src); var errorUrl = this.getAttribute('data-error-src'); if(this.src !== errorUrl) {this.src = errorUrl};"
                              loading="lazy"
                              alt="Stripe"
                              width=""
                              height="50px"
                              style=""
                              class="lazy-img loaded"
                              data-ll-status="loaded"
                            /> </span
                        ></label>
                      </div>
                    </div>
                    <h1></h1>
                    <div
                      class="tc-tooltip-dev o-layout__item u-1/1 u-1/2@xs u-1/3@md u-1/4@lg mb-20"
                      data-original-title=""
                      title=""
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="o-layout o-layout--gutter-default">
              <div
                id="j_id_2jk:j_id_36u"
                class="ui-blockui-content ui-widget ui-widget-content ui-corner-all ui-helper-hidden ui-shadow"
              ></div>
              <div
                id="j_id_2jk:boxDiscountCodePanel"
                class="trip-discount o-layout__item u-1/1 u-1/2@lg"
              >
                <div
                  id="j_id_2jk:box-discount-code"
                  class="box-discount-code success mb-20"
                >
                  <div
                    class="o-box u-box-shadow--soft u-border-radius bg-clr--white"
                  >
                    <div>
                      <h2
                        class="o-flexbox u-align-items--center u-gap--tiny u-font-size--middle u-font-weight--600 m-0"
                      >
                        <i
                          class="fa-regular fa-badge-percent u-font-size--bigger clr--dark-gray"
                        ></i>
                        Promotion code
                      </h2>
                      <div class="mt-10">
                        <div
                          class="o-layout o-layout--gutter-small o-layout--fix"
                        >
                          <div class="o-layout__item u-2/3@lg">
                            <input
                              id="j_id_2jk:promoCode"
                              name="j_id_2jk:promoCode"
                              type="text"
                              class="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all c-text-input u-1/1"
                              placeholder="Insert discount code"
                            />
                          </div>
                          <div class="o-layout__item u-1/3@lg mt-10 mt-0@lg">
                            <button
                              class="c-button u-min-width--auto@min-lg u-1/1@lg bg-clr--darkest-gray apply-promo-code-button mt-10 mt-0@sm"
                              type="button"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="j_id_38m:paymet-remarks" class="payment-remarks"></div>
            <span id="paymentButtons">
              <div
                class="o-flexbox@xs o-flexbox--generic-row@xs u-flex-direction--row-reverse payment-buttons mt-20"
              >
                <div class="">
                  <div
                    id="twoStepValidationPanel"
                    class="o-flexbox u-gap--small u-justify-content--end dev-twoStepValidationPanel"
                  >
                    <a
                      id="submitt"
                      class="ui-commandlink ui-widget c-button c-button--lg bg-clr--success payment-button u-1/1 u-width--auto@xs mb-10 mb-0@xs"
                      aria-label=""
                      @click="pay"
                      ><span>Pay $ {{ price }} USD</span></a
                    >
                  </div>
                </div>
                <a
                  id="back"
                  class="ui-commandlink ui-widget c-button c-button--lg bdr-clr--brand u-1/1 u-width--auto@xs cancel-payment-button"
                  aria-label=""
                  @click="goCancel"
                >
                  <span>Cancel</span></a
                >
              </div></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import ModalStripeCheckout from "./ModalStripeCheckout.vue";
export default {
  data() {
    return {
      iso: null,
      numberRef: null,
      reserve: null,
      week: null,
      price: null,
    };
  },
  components: {
    Header,
    ModalStripeCheckout,
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
      this.$forceUpdate();
    });

    await this.checkParams();
    await this.listBook();
  },
  methods: {
    async checkParams() {
      const { numberRef } = this.$route.query;
      this.numberRef = numberRef || null;
    },
    async listBook() {
      if (this.numberRef) {
        try {
          const { data } = await this.$axios.get(`/reserve/${this.numberRef}`);
          this.reserve = data.reserve;
          this.week = data.reserve.week;
          this.price = data.reserve.price;
          // console.log(this.countries);
        } catch (error) {
          console.log(error);
        }
      } else this.$router.push("/");
    },
    goCancel() {
      this.$router.push("/");
    },
    formatDate(date) {
      return this.$moment(date).format("LL");
    },
    async pay() {
      console.log("asads");
      await this.$modal.show("pay-checkout");
    },
    bCloseModal(){
      console.log('cerrando')
    }
  },
};
</script>
